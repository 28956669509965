import { useContext, useState } from "react";
import { Link, useLocation} from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo_wrkr-pay.svg";
import { ReactComponent as BoxArrowRight } from "bootstrap-icons/icons/box-arrow-right.svg";
import { ReactComponent as CaretDownFill } from "bootstrap-icons/icons/caret-down-fill.svg";
import Tippy from "@tippyjs/react";
import { ParticipantContext } from "../../App";
import {backToClickSuper, signOut} from "../../utils/siteNavigation";

const UserDropdown = () => {
  const { participant } = useContext(ParticipantContext);

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="user-dropdown">
      <Tippy
        theme="light-border"
        placement="bottom-end"
        animation="shift-away"
        arrow={false}
        trigger="click"
        interactive={true}
        offset={[0, 0]}
        onMount={() => setIsExpanded(true)}
        onHide={() => setIsExpanded(false)}
        content={
          <div>
            <ul className="py-2">
              <li>
                <button
                  type="button"
                  className="button button--unstyled button--icon-before"
                  onClick={ (e) =>  signOut(e)}
                  data-cy="sign-out"
                >
                  <BoxArrowRight className="button--icon-before__icon" />
                  Sign out
                </button>
              </li>
            </ul>
          </div>
        }
      >
        <button
          className="button button--unstyled button--icon-after"
          aria-expanded={isExpanded}
          data-cy="my-account"
        >
          {`Hi, ${participant?.stpUserDetails?.firstName}`}
          <CaretDownFill className="button--icon-after__icon" />
        </button>
      </Tippy>
    </div>
  );
};

// Header for signed-in users
const ProtectedHeader = () => {
  const location = useLocation();
  return location?.pathname === "/forbidden" ? null : (
      <header className="header">
        <div className="header__inner">
          <div className="header__nav-left">
            {location.pathname === "/terms-conditions" ?
                (<div className="go_clicksuper">
                    <a href="#" onClick={(e) => backToClickSuper()}>
                        Return to ClickSuper
                    </a>
                </div>) :
                (<div className="header__logo">
                  <Link to="/" aria-label="Homepage">
                    <Logo />
                  </Link>
                </div>)
            }
          </div>
          <div className="header__right">
            <UserDropdown />
          </div>
        </div>
      </header>
  );
};

export default ProtectedHeader;
