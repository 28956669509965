import { ReactComponent as ArrowRight } from "bootstrap-icons/icons/arrow-right.svg";
import { ReactComponent as Printer } from "bootstrap-icons/icons/printer.svg";
import { ReactComponent as Envelope } from "bootstrap-icons/icons/envelope.svg";
import { Link, useRouteMatch } from "react-router-dom";
import ReportSummary from "../../components/ReportSummary/ReportSummary";

const ReportSummaryDetails = ({ report }) => {
  const { url } = useRouteMatch();

  return (
    <section className="section">
      <ReportSummary report={report}/>
      {/*<div className="d-print-none">*/}
      {/*  <Link*/}
      {/*    to={`${url}/report-data`}*/}
      {/*    className="button button--outline d-flex justify-content-between mt-6"*/}
      {/*  >*/}
      {/*    View report data <ArrowRight className="ml-6" />*/}
      {/*  </Link>*/}

      {/*  <hr />*/}

      {/*  <div>*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="button button--outline button--icon-before mr-4 mb-2"*/}
      {/*      onClick={window.print}*/}
      {/*    >*/}
      {/*      <Printer className="button--icon-before__icon" />*/}
      {/*      Print*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="button button--outline button--icon-before mb-2"*/}
      {/*    >*/}
      {/*      <Envelope className="button--icon-before__icon" />*/}
      {/*      Email*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<hr />*/}

      {/*<div>*/}
      {/*  <h4>Need help?</h4>*/}
      {/*  <ul className="links">*/}
      {/*    <li>*/}
      {/*      <a href="#">Common STP Errors</a>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <a href="#">STP FAQs</a>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <a href="#">Contact support desk</a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
    </section>
  );
};

export default ReportSummaryDetails;
