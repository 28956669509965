import {resetSessionStorage} from "./token";
import {CLICK_SUPER_LOGOUT_URL, CLICK_SUPER_REDIRECT_URL} from "./configs";

export const signOut = (e) => {
  e?.preventDefault();
  resetSessionStorage();
  window.location.replace(CLICK_SUPER_LOGOUT_URL);
};

export const backToClickSuper = (e) => {
  e?.preventDefault();
  resetSessionStorage();
  window.location.replace(CLICK_SUPER_REDIRECT_URL);
};

