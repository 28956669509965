import { useState, useEffect, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { ReactComponent as EmojiFrown } from "bootstrap-icons/icons/emoji-frown.svg";
import HttpClient from "../../utils/HttpClient";
import { getPayEventStatusPillClasses } from "../../utils/format";
import { ProvideReport, useReportContext } from "../Context/ReportContext";
import StpDetailsHome from "./StpDetailsHome";
import { getStatusValue } from "../../utils/displayLabelValues";
import ProcessingModal from "../../components/Modal/ProcessingModal";
import { ReactComponent as ArrowLeftShort } from "bootstrap-icons/icons/arrow-left-short.svg";
import Spinner from "../../components/Spinner/Spinner";
import { ProcessIdParams } from "types/global";
import { STPDetailService } from "services/STPDetailService";

const InitialError = {
  status: NaN,
  message: "",
};

const StpDetailsPage = () => {
  const { processId } = useParams<ProcessIdParams>();
  const { report, setReport } = useReportContext();

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(InitialError);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const getReportData = async (processId: string) => {
    setIsLoaded(false);
    setError(InitialError);

    try {
      const res = await STPDetailService.retrieveSTPDetails(processId);

      setReport(res.data);
      setIsLoaded(true);
    } catch (err: any) {
      if (err?.response?.data?.message?.includes("still in progress")) {
        setIsLoaded(true);
        setIsOpen(true);
      } else {
        setIsLoaded(true);
        // console.log(`${err.response.status}: ${err.response.statusText}`);
        setError(err?.response?.data?.message);
        setReport({});
      }
    }
  };

  // Memorised callback to get the process data
  const getProcessDataCallback = useCallback(() => {
    getReportData(processId);
  }, [processId, setReport]);

  useEffect(() => {
    // Get process data on mount
    getProcessDataCallback();
  }, [processId, getProcessDataCallback]);

  useEffect(() => {
  }, [isLoaded]);

  return (
    <div className="stp-details">
      {/* <Breadcrumbs /> */}
      <div className="back-container mb-6">
        <Link to="/stp">
          <ArrowLeftShort />
          <span>Back to STP Reports</span>
        </Link>
      </div>
      {isOpen && (
        <ProcessingModal
          proceedHandler={() => {
            setIsOpen(false);
            history.push("/");
          }}
        />
      )}
      {isLoaded ? (
        <>
          <div className="page-header">
            <div>
              <h1 className="mb-4 d-flex align-items-center">
                {processId}{" "}
                {report?.status && (
                  <span
                    className={`pill ml-4 ${getPayEventStatusPillClasses(
                      report?.status
                    )}`}
                  >
                    {getStatusValue(report?.status)}
                  </span>
                )}
              </h1>
              <h3>
                {report?.stpVersion && (
                  <>
                    {report?.eventType === "SUBMIT" ? "Pay" : "Update"} event |{" "}
                    {report?.stpVersion === "STP2" ? "STP 2" : "STP 1"}
                  </>
                )}
              </h3>
            </div>
          </div>

          {isLoaded && !isNaN(error.status) && (
            <>
              {error.status === 404 ? (
                <div className="error-section">
                  <h2>Oops! The page you were looking for cannot be found.</h2>
                  <EmojiFrown width="4rem" height="4rem" />
                  <p>
                    You may have mistyped the address or the page may have been
                    removed.
                  </p>
                  <Link to="/" className="button button--primary">
                    Go to Homepage
                  </Link>
                </div>
              ) : (
                <div className="error-section">
                  <h2>{error.status}</h2>
                  <EmojiFrown width="4rem" height="4rem" />
                  <p>{error.message}</p>
                </div>
              )}
            </>
          )}

          {isLoaded && isNaN(error.status) && <StpDetailsHome />}
        </>) : (
        <Spinner />
      )}
    </div>
  );
};

export default function StpDetails() {
  return (
    <ProvideReport>
      <StpDetailsPage />
    </ProvideReport>
  );
}
