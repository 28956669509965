import { useEffect, useState, useContext } from "react";
import pako from "pako";
import Spinner from "../../../components/Spinner/Spinner";
import FileInput from "../../../components/FileInput/FileInput";
import NextButton from "../../../components/Buttons/NextButton";
import BackButton from "../../../components/Buttons/BackButton";
import UploadFile from "./UploadFile";
import { Link, useHistory } from "react-router-dom";
import { useReportContext } from "../../Context/ReportContext";
import PrepareReport from "./PrepareReport";
import Alert from "../../../components/Alert/Alert";
import axios from "axios";
import { AWS_ENABLED } from "../../../utils/configs";
import ProcessingModal from "../../../components/Modal/ProcessingModal";
import { ParticipantContext } from "../../../App";
import { NewReportAction } from "types/redux";
import { STPDetailResponseEventType } from "types/api";
import { STPDetailService } from "services/STPDetailService";

interface PrepareStepProps {
  dispatch: React.Dispatch<NewReportAction>;
  cancelProcess: () => void;
}

export interface ValidationRes {
  valid: boolean;
  errorCode: number;
  error: string;
  missingFields: any[];
}

const PrepareStep = ({ dispatch, cancelProcess }: PrepareStepProps) => {
  const { participant } = useContext(ParticipantContext);
  const { report, setReport } = useReportContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [fileSize, setFileSize] = useState<number>();
  const [fileType, setFileType] = useState<string>();
  const [data, setData] = useState<string | ArrayBuffer>();
  const [processId, setProcessId] = useState<string>();
  const [existingProcessId, setExistingProcessId] = useState<string>();
  const history = useHistory();
  const [isReplace, setIsReplace] = useState(false);
  const [stpMissingDetailsVersion, setStpMissingDetailsVersion] = useState<string | undefined>(undefined);
  const [error, setError] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [validationRes, setValidationRes] = useState<ValidationRes | undefined>({
    valid: true,
  } as ValidationRes);

  const changeEventType = (eventType: STPDetailResponseEventType) => {
    setExistingProcessId(report?.processId);
    setReport({ eventType: eventType });
  };

  const cancelHandler = async () => {
    setIsReplace(false);
  };

  const proceedHandler = async () => {
    setExistingProcessId(report?.processId);
    setIsReplace(true);
  };

  const retrievePreSignedUrl = async (fileName: string) => {
    return await STPDetailService.retrievePreSignedUrl(
      {
        processId: existingProcessId || "",
        fileName: fileName
      }
    );
  };

  const uploadFileToS3 = async (preSignedUrl: string, data: string | ArrayBuffer) => {
    if (AWS_ENABLED) {
      await axios.put(preSignedUrl, data, {
        headers: {
          "Content-Type": fileType || '',
        },
        transformRequest:
          (requestData: string | ArrayBuffer) => {
            return pako.gzip(requestData);
          },
      });
    }
  };

  const isFileUploaded = () => {
    return report?.fileName || fileName;
  };

  const triggerUpload = async () => {
    const uploadData = async (data: string | ArrayBuffer, fileNameOnS3: string) => {
      setStpMissingDetailsVersion(undefined);
      try {
        const preSignedUrlResponse = await retrievePreSignedUrl(fileNameOnS3);
        await uploadFileToS3(preSignedUrlResponse?.data?.preSignedUrl || "", data);

        const uploadCompleteRequestBody = {
          "fileName": fileNameOnS3
        };

        if (report?.eventType === "SUBMIT") {
          let res = await STPDetailService.submit(preSignedUrlResponse?.data?.processId || "", uploadCompleteRequestBody);
          setProcessId(res.data);
        }
        else if (report?.eventType === "UPDATE") {
          let res = await STPDetailService.update(preSignedUrlResponse?.data?.processId || "", uploadCompleteRequestBody);
          setProcessId(res.data);
        }

      } catch (err: any) {
        setIsProcessing(false);
        if (err?.response) {
          console.log("err.response - ", err?.response);
          if (err?.response?.data?.errorCode?.startsWith("STPE-002")) {
            setStpMissingDetailsVersion(
              err?.response?.data?.errorCode?.substring(9, 13)
            );
            setData(undefined);
          } else if (err?.response?.status === 500 || err?.response?.status === 400) {
            setError(err?.response?.data?.message);
            setData(undefined);
          }
        }
      }
    };

    if (data && !report?.processId) {
      setIsProcessing(true);
      // If file type is CSV then the data is already converted to XML, so use the XML extensio for the file name
      let fileNameOnS3 = fileType === "text/csv" ? fileName?.replace(/\.[^/.]+$/, "") + ".xml" : fileName
      await uploadData(data, fileNameOnS3 || "");
    } else {
      dispatch({ type: "change_step", payload: { step: 2 } });
    }
  };

  const checkRequestProgress = async () => {
    try {
      const res = await STPDetailService.validateXml(processId || "",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setReport(res.data);
      setIsProcessing(false);
      dispatch({ type: "change_step", payload: { step: 2 } });
    } catch (err: any) {
      if (err?.response) {
        console.log("err.response - ", err?.response);
        if (err?.response?.status === 400) {
          setReport(err?.response?.data);
          setIsProcessing(false);
          dispatch({ type: "change_step", payload: { step: 2 } });
        } else if (
          err?.response?.data?.message?.includes("still in progress")
        ) {
          setIsProcessing(false);
          setIsOpen(true);
        } else {
          setIsProcessing(false);
          console.log("Error - ", err);
          setError(err?.response?.data?.message);
          setData(undefined);
        }
      }
    }
  };

  useEffect(() => {

    if (processId && !report?.processId) {
      setTimeout(() => {
        checkRequestProgress();
      }, 10000);
    }
  }, [report, processId]);

  useEffect(() => { }, [stpMissingDetailsVersion]);

  return (
    <div className="section">
      <h2>Prepare your report data</h2>

      {isOpen && (
        <ProcessingModal proceedHandler={() => {
          setIsOpen(false);
          history.push("/");
        }}
        />
      )}
      
      {isProcessing ? (
        <Spinner
          message="It can take up to several minutes if the file size is large.<br />Please do not refresh this page."
          header="Processing data..."
        />
      ) : (
        <div>
          <PrepareReport eventTypeHandler={changeEventType} />
          <div className="section-container">
            <h3>Upload file</h3>
            {isFileUploaded() && (
              <UploadFile
                fileName={report?.fileName ? report?.fileName : fileName}
                fileSize={fileSize}
                handleReset={proceedHandler}
                cancelHandler={cancelHandler}
                isReplace={isReplace}
              />
            )}
            {(!isFileUploaded() || isReplace) && (
              <FileInput
                onRead={(fileName, fileSize, fileType, fileData, validationData) => {
                  setFileName(fileName);
                  setFileSize(fileSize);
                  setFileType(fileType);
                  setData(fileData);
                  setIsReplace(false);
                  setReport({ eventType: report?.eventType });
                  setStpMissingDetailsVersion(undefined);
                  setError(undefined);
                  setValidationRes(validationData)
                }}
                participant={participant}
              />
            )}
            {stpMissingDetailsVersion && !isReplace && (
              <Alert
                variation="alert--failure"
                title={`Unverified ${stpMissingDetailsVersion} details`}
              >
                {() => {
                  return (
                    <div>
                      <p>
                        You have uploaded an {stpMissingDetailsVersion} file.
                        Please verify your {stpMissingDetailsVersion} details in
                        order to proceed.
                      </p>
                      <Link
                        className="button--link"
                        to={`/settings/stp-details/${stpMissingDetailsVersion}`}
                      >
                        Verify your {stpMissingDetailsVersion} details here
                      </Link>
                    </div>
                  );
                }}
              </Alert>
            )}
            {error && !isReplace && (
              <Alert variation="alert--failure" title={`File upload error`}>
                {() => {
                  return (
                    <div>
                      <p>{error}</p>
                    </div>
                  );
                }}
              </Alert>
            )}
            {validationRes && !validationRes.valid && (
              <Alert variation="alert--failure" title={validationRes.error}>
                {() => {
                  return (
                    <div>
                      {validationRes?.errorCode === 2 ? <p>These fields are invalid or missing: </p> : <p>{validationRes?.error}</p>}
                      {validationRes?.errorCode === 2 ? validationRes?.missingFields.map((fieldName) => <div>&#x2022; {fieldName}</div>) : null}
                    </div>
                  );
                }}
              </Alert>
            )}
          </div>
          <div className="button-group">
            <div>
              <BackButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Back
              </BackButton>
              <NextButton
                onClick={triggerUpload}
                disabled={!isFileUploaded() || isProcessing || error || stpMissingDetailsVersion || (validationRes && !validationRes?.valid)}
              >
                Next
              </NextButton>
            </div>
            {report?.processId && (
              <div>
                <button
                  type="button"
                  className="button button--underline"
                  onClick={cancelProcess}
                >
                  Cancel report
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrepareStep;
