import BackButton from "../../../components/Buttons/BackButton";
import { useEffect, useState } from "react";
import HttpClient from "../../../utils/HttpClient";
import Spinner from "../../../components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { useReportContext } from "../../Context/ReportContext";
import { RotatingLines } from 'react-loader-spinner';

const LodgeStep = ({ dispatch, onLodge, cancelProcess, processState }) => {
  const { report } = useReportContext();
  const [accepted, setAccepted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const [declaration, setDeclaration] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const updateStep = async () => {
      try {
        const res = await HttpClient.put(
          `/stp-details/${report.processId}/step/3`
        );
      } catch (err) {
        console.log("Error - ", err);
      }
    };

    if (report?.processId) {
      updateStep();
    }
  }, [report?.processId]);

  useEffect(() => {
    const getDeclaration = async () => {
      try {
        const res = await HttpClient.get(
          `/declaration/${report?.payevntSummary?.declarationType}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setDeclaration(res.data);
        setIsProcessing(false);
      } catch (err) {
        console.log("Error - ", err);
      }
    };
    if (declaration == null) {
      getDeclaration();
    }
  }, [isProcessing, declaration, report]);

  return (
    <div className="section">
      <h2>Lodge your report</h2>
      {isProcessing ? (
        <Spinner />
      ) : (
        <div>
          <div className="declaration mb-8">
            {report?.batch ? (
              <h3>Your STP report is ready for lodgement</h3>
            ) : (
              <h3>
                Your STP report for {report?.payevntSummary?.payDate} is ready
                for lodgement
              </h3>
            )}
            <div className="my-6">
              <div
                dangerouslySetInnerHTML={{
                  __html: declaration.declarationStatement,
                }}
              />
            </div>
            <div className="declaration__checkbox">
              <div className="checkbox">
                <input
                  id="declaration"
                  name="declaration"
                  type="checkbox"
                  checked={accepted}
                  onChange={(event) => setAccepted(event.target.checked)}
                />
                <label htmlFor="declaration">
                  {declaration.signingStatement}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="button-group">
        <div>
          <BackButton
            onClick={() =>
              dispatch({ type: "change_step", payload: { step: 2 } })
            }
          >
            Back
          </BackButton>
          <button
            type="button"
            className="button button--next"
            onClick={onLodge}
            disabled={!accepted || processState?.isLodging}
          >
            {processState?.isLodging ? "Lodging... ": "Lodge"}
          </button>
          <button
            type="button"
            className="button button--underline px-2"
            onClick={() => {
              history.push("/stp");
            }}
          >
            Save and quit
          </button>
        </div>
        <div>
          <button
            type="button"
            className="button button--underline"
            onClick={cancelProcess}
          >
            Cancel report
          </button>
        </div>
      </div>
    </div>
  );
};

export default LodgeStep;
