import Modal from "react-modal";
import Button from "../Buttons/Button";

Modal.setAppElement("#root");

const ProcessingModal = ({ proceedHandler }) => {
  return (
    <Modal
      className="modal"
      overlayClassName="modal-overlay"
      isOpen={true}
      contentLabel="Processing"
    >
      <h3 className="mt-5">Processing large file...</h3>
      <p className="my-4">
        <span>
          This STP report is taking longer to process due to its larger file
          size. Once processing is complete, you can return to complete the
          lodgement of this STP report.
        </span>
      </p>
      <Button className="button button--outline" onClick={proceedHandler}>
        OK
      </Button>
    </Modal>
  );
};

export default ProcessingModal;
