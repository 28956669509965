import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { NavLink } from "react-router-dom";

const Settings = ({ routes }) => {
  return (
    <div className="settings content-container">
      {/* <Breadcrumbs /> */}

      <div className="d-flex align-items-center justify-content-between mb-8">
        <h1 className="mb-0">Settings</h1>
      </div>

      <ul className="contents">
        <li className="contents__item contents__item--stp">
          <NavLink to="/settings/stp-details/STP1" activeClassName="is-active">
            <h2>STP details</h2>
            <p className="p--sm">
              Product ID and BMS details for STP1 and STP2 report lodgements
            </p>
          </NavLink>
        </li>
        {/*<li className="contents__item contents__item--employer">*/}
        {/*  <a href="#">*/}
        {/*    <h2>Employer details</h2>*/}
        {/*    <p className="p--sm">*/}
        {/*      Employer/Payer details recorded with STP submissions*/}
        {/*    </p>*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="contents__item contents__item--billing">*/}
        {/*  <a href="#">*/}
        {/*    <h2>Billing</h2>*/}
        {/*    <p className="p--sm">Manage billing details</p>*/}
        {/*  </a>*/}
        {/*</li>*/}
        {/*<li className="contents__item contents__item--users">*/}
        {/*  <a href="#">*/}
        {/*    <h2>Users</h2>*/}
        {/*    <p className="p--sm">Manage user roles and permissions</p>*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </div>
  );
};

export default Settings;
