export const CSVTOXMLMapper = {
    "BMS Identifier": "/tns:PAYEVNT/tns:Rp/tns:SoftwareInformationBusinessManagementSystemId",
    "Payer Australian Business Number": "/tns:PAYEVNT/tns:Rp/tns:AustralianBusinessNumberId",
    "Payer Withholding Payer Number": "/tns:PAYEVNT/tns:Rp/tns:WithholdingPayerNumberId",
    "Payer Branch Code": "/tns:PAYEVNT/tns:Rp/tns:OrganisationDetailsOrganisationBranchC",
    "Previous BMS Identifier": "/tns:PAYEVNT/tns:Rp/tns:PreviousSoftwareInformationBusinessManagementSystemId",
    "Payer Organisation Name": "/tns:PAYEVNT/tns:Rp/tns:OrganisationName/tns:DetailsOrganisationalNameT",
    "Payer Contact Name": "/tns:PAYEVNT/tns:Rp/tns:OrganisationName/tns:PersonUnstructuredNameFullNameT",
    "Payer E-mail Address": "/tns:PAYEVNT/tns:Rp/tns:ElectronicContact/tns:ElectronicMailAddressT",
    "Payer Business Hours Phone Number": "/tns:PAYEVNT/tns:Rp/tns:ElectronicContact/tns:TelephoneMinimalN",
    "Payer Postcode": "/tns:PAYEVNT/tns:Rp/tns:AddressDetailsPostal/tns:PostcodeT",
    "Payer Country Code": "/tns:PAYEVNT/tns:Rp/tns:AddressDetailsPostal/tns:CountryC",
    "Pay/Update Date": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:PaymentRecordTransactionD",
    "Payee Record Count": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:InteractionRecordCt",
    "Run Date/Time Stamp": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:MessageTimestampGenerationDt",
    "Submission ID": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:InteractionTransactionId",
    "Full File Replacement Indicator": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:AmendmentI",
    "Payer Total PAYGW Amount": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:IncomeTaxAndRemuneration/tns:PayAsYouGoWithholdingTaxWithheldA",
    "Payer Total Gross Payments Amount": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:IncomeTaxAndRemuneration/tns:TotalGrossPaymentsWithholdingA",
    "Child Support Total Garnishee Amount": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:IncomeTaxAndRemuneration/tns:ChildSupportGarnisheeA",
    "Child Support Total Deductions Amount": "/tns:PAYEVNT/tns:Rp/tns:Payroll/tns:IncomeTaxAndRemuneration/tns:ChildSupportWithholdingA",
    "Payer Declarer Identifier": "/tns:PAYEVNT/tns:Rp/tns:Declaration/tns:SignatoryIdentifierT",
    "Payer Declaration Date": "/tns:PAYEVNT/tns:Rp/tns:Declaration/tns:SignatureD",
    "Payer Declaration Acceptance Indicator": "/tns:PAYEVNT/tns:Rp/tns:Declaration/tns:StatementAcceptedI",
    "Intermediary ABN": "/tns:PAYEVNT/tns:Int/tns:AustralianBusinessNumberId",
    "Registered Agent Number": "/tns:PAYEVNT/tns:Int/tns:TaxAgentNumberId",
    "Intermediary Contact Name": "/tns:PAYEVNT/tns:Int/tns:PersonUnstructuredNameFullNameT",
    "Intermediary E-mail Address": "/tns:PAYEVNT/tns:Int/tns:ElectronicContact/tns:ElectronicMailAddressT",
    "Intermediary Business Hours Phone Number": "/tns:PAYEVNT/tns:Int/tns:ElectronicContact/tns:TelephoneMinimalN",
    "Intermediary Declarer Identifier": "/tns:PAYEVNT/tns:Int/tns:Declaration/tns:SignatoryIdentifierT",
    "Intermediary Declaration Date": "/tns:PAYEVNT/tns:Int/tns:Declaration/tns:SignatureD",
    "Intermediary Declaration Acceptance Indicator": "/tns:PAYEVNT/tns:Int/tns:Declaration/tns:StatementAcceptedI",
    "Payee TFN": "/tns:PAYEVNTEMP/tns:Payee/tns:Identifiers/tns:TaxFileNumberId",
    "Contractor ABN": "/tns:PAYEVNTEMP/tns:Payee/tns:Identifiers/tns:AustralianBusinessNumberId",
    "Payee Payroll ID": "/tns:PAYEVNTEMP/tns:Payee/tns:Identifiers/tns:EmploymentPayrollNumberId",
    "Previous Payroll ID": "/tns:PAYEVNTEMP/tns:Payee/tns:Identifiers/tns:PreviousPayrollIDEmploymentPayrollNumberId",
    "Payee Family Name": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonNameDetails/tns:FamilyNameT",
    "Payee First Name": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonNameDetails/tns:GivenNameT",
    "Payee Other Name": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonNameDetails/tns:OtherGivenNameT",
    "Payee Day of Birth": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonDemographicDetailsBirth/tns:Dm",
    "Payee Month of Birth": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonDemographicDetailsBirth/tns:M",
    "Payee Year of Birth": "/tns:PAYEVNTEMP/tns:Payee/tns:PersonDemographicDetailsBirth/tns:Y",
    "Payee Address Line 1": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:Line1T",
    "Payee Address Line 2": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:Line2T",
    "Payee Suburb": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:LocalityNameT",
    "Payee State": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:StateOrTerritoryC",
    "Payee Postcode": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:PostcodeT",
    "Payee Country Code": "/tns:PAYEVNTEMP/tns:Payee/tns:AddressDetails/tns:CountryC",
    "Payee E-mail Address": "/tns:PAYEVNTEMP/tns:Payee/tns:ElectronicContact/tns:ElectronicMailAddressT",
    "Payee Phone Number": "/tns:PAYEVNTEMP/tns:Payee/tns:ElectronicContact/tns:TelephoneMinimalN",
    "Payee Commencement Date": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:EmploymentStartD",
    "Payee Cessation Date": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:EmploymentEndD",
    "Employment Basis Code": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:PaymentBasisC",
    "Cessation Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:CessationTypeC",
    "Tax Treatment Code": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:TaxTreatmentC",
    "Tax Offset Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:EmployerConditions/tns:TaxOffsetClaimTotalA",
    "Period Start Date": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:StartD",
    "Period End Date": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:EndD",
    "Final Event Indicator": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationPayrollEventFinalI",
    "Income Stream Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:IncomeStreamTypeC",
    "Country Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:AddressDetailsCountryC",
    "PAYGW Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:IncomeTaxPayAsYouGoWithholdingTaxWithheldA",
    "Foreign Tax Paid Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:IncomeTaxForeignWithholdingA",
    "Exempt Foreign Income Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:IndividualNonBusinessExemptForeignEmploymentIncomeA",
    "Gross Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:GrossA",
    "Paid Leave Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:PaidLeaveCollection/tns:PaidLeave/tns:TypeC",
    "Paid Leave Payment Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:PaidLeaveCollection/tns:PaidLeave/tns:PaymentA",
    "Allowance Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:AllowanceCollection/tns:Allowance/tns:TypeC",
    "Other Allowance Type Description": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:AllowanceCollection/tns:Allowance/tns:OtherAllowanceTypeDe",
    "Payee Allowance Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:AllowanceCollection/tns:Allowance/tns:EmploymentAllowancesA",
    "Overtime Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:OvertimePaymentA",
    "Bonuses and Commissions Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:GrossBonusesAndCommissionsA",
    "Directors' Fees Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:GrossDirectorsFeesA",
    "CDEP Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:IndividualNonBusinessCommunityDevelopmentEmploymentProjectA",
    "Salary Sacrifice Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:SalarySacrificeCollection/tns:SalarySacrifice/tns:TypeC",
    "Salary Sacrifice Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:SalarySacrificeCollection/tns:SalarySacrifice/tns:PaymentA",
    "Lump Sum Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:LumpSumCollection/tns:LumpSum/tns:TypeC",
    "Lump Sum Financial Year": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:LumpSumCollection/tns:LumpSum/tns:FinancialY",
    "Lump Sum Payment Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:LumpSumCollection/tns:LumpSum/tns:PaymentsA",
    "ETP Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:EmploymentTerminationPaymentCollection/tns:EmploymentTerminationPayment/tns:IncomeTaxPayAsYouGoWithholdingTypeC",
    "Payee ETP Payment Date": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:EmploymentTerminationPaymentCollection/tns:EmploymentTerminationPayment/tns:IncomeD",
    "Payee Termination Payment Tax Free Component": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:EmploymentTerminationPaymentCollection/tns:EmploymentTerminationPayment/tns:IncomeTaxFreeA",
    "Payee Termination Payment Taxable Component": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:EmploymentTerminationPaymentCollection/tns:EmploymentTerminationPayment/tns:IncomeTaxableA",
    "Payee Total ETP PAYG Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:RemunerationCollection/tns:Remuneration/tns:EmploymentTerminationPaymentCollection/tns:EmploymentTerminationPayment/tns:IncomePayAsYouGoWithholdingA",
    "Deduction Type": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:DeductionCollection/tns:Deduction/tns:RemunerationTypeC",
    "Payee Deduction Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:DeductionCollection/tns:Deduction/tns:RemunerationA",
    "Super Entitlement Type Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:SuperannuationContributionCollection/tns:SuperannuationContribution/tns:EntitlementTypeC",
    "Super Entitlement Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:SuperannuationContributionCollection/tns:SuperannuationContribution/tns:EmployerContributionsYearToDateA",
    "RFB Exemption Status Code": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:IncomeFringeBenefitsReportableCollection/tns:IncomeFringeBenefitsReportable/tns:FringeBenefitsReportableExemptionC",
    "Payee RFB Amount": "/tns:PAYEVNTEMP/tns:Payee/tns:PayrollPeriod/tns:IncomeFringeBenefitsReportableCollection/tns:IncomeFringeBenefitsReportable/tns:A",
}

export type AllPropertyObject = {
    [Value in keyof typeof CSVTOXMLMapper]: string;
};

export const checkCSVColumns = (data: AllPropertyObject[]) => {
    const columnNames = Object.keys(CSVTOXMLMapper);
    if (!data || data.length < 1 || !data[0]) {
        return { valid: false, errorCode: 1, error: "Invalid or missing field names", missingFields: [] }
    } else {
        let missingFields: string[] = []
        columnNames.forEach(name => {
            if (!data[0].hasOwnProperty(name)) {
                missingFields.push(name);
            }
        })
        return missingFields.length > 0 ?
            { valid: false, errorCode: 2, error: "Invalid or missing field names", missingFields: missingFields } : undefined
    }
}

const convertDateShortDateToISOInt = (value: any) => {
    if (value.includes('/')) {
        return value.split("/").reverse().join("-")
    }
    return value
}

export const generateXML = (data: AllPropertyObject[]) => {
    let count = 1;
    let res = "";
    const parents = new Map();
    data.forEach((rec: AllPropertyObject) => {
        if (!isEmptyLine(rec)) {
            if (parents.has(rec["Submission ID"])) {
                res = res + `<Record_Delimiter DocumentID="${count}" DocumentType="CHILD" DocumentName="PAYEVNTEMP" RelatedDocumentID="${parents.get(rec["Submission ID"])}"/> \n`;
            }
            else {
                res = res + `<Record_Delimiter DocumentID="${count}" DocumentType="PARENT" DocumentName="PAYEVNT" RelatedDocumentID=""/> \n`;
                parents.set(rec["Submission ID"], count);
                res = res + generatePayeeEvent(rec);
                count = count + 1;
                res = res + `<Record_Delimiter DocumentID="${count}" DocumentType="CHILD" DocumentName="PAYEVNTEMP" RelatedDocumentID="${count - 1}"/> \n`;
            }
            res = res + generatePayeeEventEmp(rec);
            count = count + 1;
        }
    })
    return res;
}

const isNullorEmpty = (value: any) => {
    return value ? (value === "null" || value === "NULL") : true
}


const generatePayeeEvent = (data: AllPropertyObject) => {
    let res = `<tns:PAYEVNT xsi:schemaLocation="http://www.sbr.gov.au/ato/payevnt ato.payevnt.0004.2020.01.00.xsd" xmlns:tns="http://www.sbr.gov.au/ato/payevnt" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"> \n`
    res = res + `<tns:Rp> \n`
    res = res + `<tns:SoftwareInformationBusinessManagementSystemId>${data['BMS Identifier']}</tns:SoftwareInformationBusinessManagementSystemId> \n`

    if (!isNullorEmpty(data['Payer Australian Business Number'])) {
        res = res + `<tns:AustralianBusinessNumberId>${data['Payer Australian Business Number']}</tns:AustralianBusinessNumberId> \n`
    }
    if (!isNullorEmpty(data['Payer Withholding Payer Number'])) {
        res = res + `<tns:WithholdingPayerNumberId>${data['Payer Withholding Payer Number']}</tns:WithholdingPayerNumberId> \n`
    }
    if (!isNullorEmpty(data['Payer Branch Code'])) {
        res = res + `<tns:OrganisationDetailsOrganisationBranchC>${data['Payer Branch Code']}</tns:OrganisationDetailsOrganisationBranchC> \n`
    }
    if (!isNullorEmpty(data['Previous BMS Identifier'])) {
        res = res + `<tns:PreviousSoftwareInformationBusinessManagementSystemId>${data['Previous BMS Identifier']}</tns:PreviousSoftwareInformationBusinessManagementSystemId> \n`
    }

    if (!isNullorEmpty(data['Payer Organisation Name']) || !isNullorEmpty(data['Payer Contact Name'])) {
        res = res + `<tns:OrganisationName> \n`
        if (!isNullorEmpty(data['Payer Organisation Name'])) {
            res = res + `<tns:DetailsOrganisationalNameT>${data['Payer Organisation Name']}</tns:DetailsOrganisationalNameT> \n`
        }
        if (!isNullorEmpty(data['Payer Contact Name'])) {
            res = res + `<tns:PersonUnstructuredNameFullNameT>${data['Payer Contact Name']}</tns:PersonUnstructuredNameFullNameT> \n`
        }
        res = res + `</tns:OrganisationName> \n`
    }

    if (!isNullorEmpty(data['Payer E-mail Address']) || !isNullorEmpty(data['Payer Business Hours Phone Number'])) {
        res = res + `<tns:ElectronicContact> \n`
        if (!isNullorEmpty(data['Payer E-mail Address'])) {
            res = res + `<tns:ElectronicMailAddressT>${data['Payer E-mail Address']}</tns:ElectronicMailAddressT> \n`
        }
        if (!isNullorEmpty(data['Payer Business Hours Phone Number'])) {
            res = res + `<tns:TelephoneMinimalN>${data['Payer Business Hours Phone Number']}</tns:TelephoneMinimalN> \n`
        }
        res = res + `</tns:ElectronicContact> \n`
    }

    if (!isNullorEmpty(data['Payer Postcode']) || !isNullorEmpty(data['Payer Country Code'])) {
        res = res + `<tns:AddressDetailsPostal> \n`
        if (!isNullorEmpty(data['Payer Postcode'])) {
            res = res + `<tns:PostcodeT>${data['Payer Postcode']}</tns:PostcodeT> \n`
        }
        if (!isNullorEmpty(data['Payer Country Code'])) {
            res = res + `<tns:CountryC>${data['Payer Country Code'].toLowerCase()}</tns:CountryC> \n`
        }
        res = res + `</tns:AddressDetailsPostal> \n`
    }

    res = res + `<tns:Payroll> \n`
    if (!isNullorEmpty(data['Pay/Update Date'])) {
        res = res + `<tns:PaymentRecordTransactionD>${convertDateShortDateToISOInt(data['Pay/Update Date'])}</tns:PaymentRecordTransactionD> \n`
    }
    if (!isNullorEmpty(data['Payee Record Count'])) {
        res = res + `<tns:InteractionRecordCt>${data['Payee Record Count']}</tns:InteractionRecordCt> \n`
    }
    if (!isNullorEmpty(data['Run Date/Time Stamp'])) {
        res = res + `<tns:MessageTimestampGenerationDt>${data['Run Date/Time Stamp']}</tns:MessageTimestampGenerationDt> \n`
    }
    if (!isNullorEmpty(data['Submission ID'])) {
        res = res + `<tns:InteractionTransactionId>${data['Submission ID']}</tns:InteractionTransactionId> \n`
    }
    if (!isNullorEmpty(data['Full File Replacement Indicator'])) {
        res = res + `<tns:AmendmentI>${data['Full File Replacement Indicator'].toLowerCase()}</tns:AmendmentI> \n`
    }
    if (!isNullorEmpty(data['Payer Total PAYGW Amount']) || !isNullorEmpty(data['Payer Total Gross Payments Amount']) ||
        !isNullorEmpty(data['Child Support Total Garnishee Amount']) || !isNullorEmpty(data['Child Support Total Deductions Amount'])) {
        res = res + `<tns:IncomeTaxAndRemuneration>`
        if (!isNullorEmpty(data['Payer Total PAYGW Amount'])) {
            res = res + `<tns:PayAsYouGoWithholdingTaxWithheldA>${data['Payer Total PAYGW Amount']}</tns:PayAsYouGoWithholdingTaxWithheldA> \n`
        }
        if (!isNullorEmpty(data['Payer Total Gross Payments Amount'])) {
            res = res + `<tns:TotalGrossPaymentsWithholdingA>${data['Payer Total Gross Payments Amount']}</tns:TotalGrossPaymentsWithholdingA> \n`
        }
        if (!isNullorEmpty(data['Child Support Total Garnishee Amount'])) {
            res = res + `<tns:ChildSupportGarnisheeA>${data['Child Support Total Garnishee Amount']}</tns:ChildSupportGarnisheeA> \n`
        }
        if (!isNullorEmpty(data['Child Support Total Deductions Amount'])) {
            res = res + `<tns:ChildSupportWithholdingA>${data['Child Support Total Deductions Amount']}</tns:ChildSupportWithholdingA> \n`
        }
        res = res + `</tns:IncomeTaxAndRemuneration> \n `
    }

    res = res + `</tns:Payroll> \n <tns:Declaration> \n`

    if (!isNullorEmpty(data['Payer Declarer Identifier'])) {
        res = res + `<tns:SignatoryIdentifierT>${data['Payer Declarer Identifier']}</tns:SignatoryIdentifierT> \n`
    }
    if (!isNullorEmpty(data['Payer Declaration Date'])) {
        res = res + `<tns:SignatureD>${convertDateShortDateToISOInt(data['Payer Declaration Date'])}</tns:SignatureD> \n`
    }
    if (!isNullorEmpty(data['Payer Declaration Acceptance Indicator'])) {
        res = res + `<tns:StatementAcceptedI>${data['Payer Declaration Acceptance Indicator'].toLowerCase()}</tns:StatementAcceptedI> \n`
    }
    res = res + `</tns:Declaration></tns:Rp><tns:Int>`
    if (!isNullorEmpty(data['Intermediary ABN'])) {
        res = res + `<tns:AustralianBusinessNumberId>${data['Intermediary ABN']}</tns:AustralianBusinessNumberId> \n`
    }
    if (!isNullorEmpty(data['Registered Agent Number'])) {
        res = res + `<tns:TaxAgentNumberId>${data['Registered Agent Number']}</tns:TaxAgentNumberId> \n`
    }
    if (!isNullorEmpty(data['Intermediary Contact Name'])) {
        res = res + `<tns:PersonUnstructuredNameFullNameT>${data['Intermediary Contact Name']}</tns:PersonUnstructuredNameFullNameT> \n`
    }

    res = res + `<tns:ElectronicContact> \n`
    if (!isNullorEmpty(data['Intermediary E-mail Address'])) {
        res = res + `<tns:ElectronicMailAddressT>${data['Intermediary E-mail Address']}</tns:ElectronicMailAddressT> \n`
    }
    if (!isNullorEmpty(data['Intermediary Business Hours Phone Number'])) {
        res = res + `<tns:TelephoneMinimalN>${data['Intermediary Business Hours Phone Number']}</tns:TelephoneMinimalN> \n`
    }

    res = res + `</tns:ElectronicContact> \n <tns:Declaration>\n`
    if (!isNullorEmpty(data['Intermediary Declarer Identifier'])) {
        res = res + `<tns:SignatoryIdentifierT>${data['Intermediary Declarer Identifier']}</tns:SignatoryIdentifierT> \n`
    }
    if (!isNullorEmpty(data['Intermediary Declaration Date'])) {
        res = res + `<tns:SignatureD>${convertDateShortDateToISOInt(data['Intermediary Declaration Date'])}</tns:SignatureD> \n`
    }
    if (!isNullorEmpty(data['Intermediary Declaration Acceptance Indicator'])) {
        res = res + `<tns:StatementAcceptedI>${data['Intermediary Declaration Acceptance Indicator'].toLowerCase()}</tns:StatementAcceptedI> \n`
    }
    res = res + `</tns:Declaration> \n</tns:Int> \n</tns:PAYEVNT> \n`

    return res;
}


const generatePayeeEventEmp = (data: AllPropertyObject) => {
    let res = `<tns:PAYEVNTEMP xsi:schemaLocation="http://www.sbr.gov.au/ato/payevntemp ato.payevntemp.0004.2020.01.00.xsd" xmlns:tns="http://www.sbr.gov.au/ato/payevntemp" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"> \n`
    res = res + `<tns:Payee> \n<tns:Identifiers> \n`
    if (!isNullorEmpty(data['Payee TFN'])) {
        res = res + `<tns:TaxFileNumberId>${data['Payee TFN']}</tns:TaxFileNumberId> \n`
    }
    if (!isNullorEmpty(data['Contractor ABN'])) {
        res = res + `<tns:AustralianBusinessNumberId>${data['Contractor ABN']}</tns:AustralianBusinessNumberId> \n`
    }
    if (!isNullorEmpty(data['Payee Payroll ID'])) {
        res = res + `<tns:EmploymentPayrollNumberId>${data['Payee Payroll ID']}</tns:EmploymentPayrollNumberId> \n`
    }
    if (!isNullorEmpty(data['Previous Payroll ID'])) {
        res = res + `<tns:PreviousPayrollIDEmploymentPayrollNumberId>${data['Previous Payroll ID']}</tns:PreviousPayrollIDEmploymentPayrollNumberId> \n`
    }

    res = res + `</tns:Identifiers>\n<tns:PersonNameDetails>\n`
    if (!isNullorEmpty(data['Payee Family Name'])) {
        res = res + `<tns:FamilyNameT>${data['Payee Family Name']}</tns:FamilyNameT> \n`
    }
    if (!isNullorEmpty(data['Payee First Name'])) {
        res = res + `<tns:GivenNameT>${data['Payee First Name']}</tns:GivenNameT> \n`
    }
    if (!isNullorEmpty(data['Payee Other Name'])) {
        res = res + `<tns:OtherGivenNameT>${data['Payee Other Name']}</tns:OtherGivenNameT> \n`
    }

    res = res + `</tns:PersonNameDetails>\n<tns:PersonDemographicDetailsBirth>\n`
    if (!isNullorEmpty(data['Payee Day of Birth'])) {
        res = res + `<tns:Dm>${data['Payee Day of Birth']}</tns:Dm> \n`
    }
    if (!isNullorEmpty(data['Payee Month of Birth'])) {
        res = res + `<tns:M>${data['Payee Month of Birth']}</tns:M> \n`
    }
    if (!isNullorEmpty(data['Payee Year of Birth'])) {
        res = res + `<tns:Y>${data['Payee Year of Birth']}</tns:Y> \n`
    }

    res = res + `</tns:PersonDemographicDetailsBirth>\n<tns:AddressDetails>\n`
    if (!isNullorEmpty(data['Payee Address Line 1'])) {
        res = res + `<tns:Line1T>${data['Payee Address Line 1']}</tns:Line1T> \n`
    }
    if (!isNullorEmpty(data['Payee Address Line 2'])) {
        res = res + `<tns:Line2T>${data['Payee Address Line 2']}</tns:Line2T> \n`
    }
    if (!isNullorEmpty(data['Payee Suburb'])) {
        res = res + `<tns:LocalityNameT>${data['Payee Suburb']}</tns:LocalityNameT> \n`
    }
    if (!isNullorEmpty(data['Payee State'])) {
        res = res + `<tns:StateOrTerritoryC>${data['Payee State']}</tns:StateOrTerritoryC> \n`
    }
    if (!isNullorEmpty(data['Payee Postcode'])) {
        res = res + `<tns:PostcodeT>${data['Payee Postcode']}</tns:PostcodeT> \n`
    }
    if (!isNullorEmpty(data['Payee Country Code'])) {
        res = res + `<tns:CountryC>${data['Payee Country Code'].toLowerCase()}</tns:CountryC> \n`
    }

    res = res + `</tns:AddressDetails>\n<tns:ElectronicContact>\n`
    if (!isNullorEmpty(data['Payee E-mail Address'])) {
        res = res + `<tns:ElectronicMailAddressT>${data['Payee E-mail Address']}</tns:ElectronicMailAddressT>\n`
    }
    if (!isNullorEmpty(data['Payee Phone Number'])) {
        res = res + `<tns:TelephoneMinimalN>${data['Payee Phone Number']}</tns:TelephoneMinimalN>\n`
    }

    res = res + `</tns:ElectronicContact>\n<tns:EmployerConditions>\n`
    if (!isNullorEmpty(data['Payee Commencement Date'])) {
        res = res + `<tns:EmploymentStartD>${convertDateShortDateToISOInt(data['Payee Commencement Date'])}</tns:EmploymentStartD>\n`
    }
    if (!isNullorEmpty(data['Payee Cessation Date'])) {
        res = res + `<tns:EmploymentEndD>${convertDateShortDateToISOInt(data['Payee Cessation Date'])}</tns:EmploymentEndD>\n`
    }
    if (!isNullorEmpty(data['Employment Basis Code'])) {
        res = res + `<tns:PaymentBasisC>${data['Employment Basis Code']}</tns:PaymentBasisC>\n`
    }
    if (!isNullorEmpty(data['Cessation Type Code'])) {
        res = res + `<tns:CessationTypeC>${data['Cessation Type Code']}</tns:CessationTypeC>\n`
    }
    if (!isNullorEmpty(data['Tax Treatment Code'])) {
        res = res + `<tns:TaxTreatmentC>${data['Tax Treatment Code']}</tns:TaxTreatmentC>\n`
    }
    if (!isNullorEmpty(data['Tax Offset Amount'])) {
        res = res + `<tns:TaxOffsetClaimTotalA>${data['Tax Offset Amount']}</tns:TaxOffsetClaimTotalA>\n`
    }

    res = res + `</tns:EmployerConditions>\n<tns:PayrollPeriod>\n`

    if (!isNullorEmpty(data['Period Start Date'])) {
        res = res + `<tns:StartD>${convertDateShortDateToISOInt(data['Period Start Date'])}</tns:StartD>\n`
    }
    if (!isNullorEmpty(data['Period End Date'])) {
        res = res + `<tns:EndD>${convertDateShortDateToISOInt(data['Period End Date'])}</tns:EndD>\n`
    }
    if (!isNullorEmpty(data['Final Event Indicator'])) {
        res = res + `<tns:RemunerationPayrollEventFinalI>${data['Final Event Indicator'].toLowerCase()}</tns:RemunerationPayrollEventFinalI>\n`
    }

    res = res + `<tns:RemunerationCollection>\n<tns:Remuneration>\n`
    if (!isNullorEmpty(data['Income Stream Type Code'])) {
        res = res + `<tns:IncomeStreamTypeC>${data['Income Stream Type Code']}</tns:IncomeStreamTypeC>\n`
    }
    if (!isNullorEmpty(data['Country Code'])) {
        res = res + `<tns:AddressDetailsCountryC>${data['Country Code'].toLowerCase()}</tns:AddressDetailsCountryC>\n`
    }
    if (!isNullorEmpty(data['PAYGW Amount'])) {
        res = res + `<tns:IncomeTaxPayAsYouGoWithholdingTaxWithheldA>${data['PAYGW Amount']}</tns:IncomeTaxPayAsYouGoWithholdingTaxWithheldA>\n`
    }
    if (!isNullorEmpty(data['Foreign Tax Paid Amount'])) {
        res = res + `<tns:IncomeTaxForeignWithholdingA>${data['Foreign Tax Paid Amount']}</tns:IncomeTaxForeignWithholdingA>\n`
    }
    if (!isNullorEmpty(data['Exempt Foreign Income Amount'])) {
        res = res + `<tns:IndividualNonBusinessExemptForeignEmploymentIncomeA>${data['Exempt Foreign Income Amount']}</tns:IndividualNonBusinessExemptForeignEmploymentIncomeA>\n`
    }
    if (!isNullorEmpty(data['Gross Amount'])) {
        res = res + `<tns:GrossA>${data['Gross Amount']}</tns:GrossA>\n`
    }
    if (!isNullorEmpty(data['Overtime Amount'])) {
        res = res + `<tns:OvertimePaymentA>${data['Overtime Amount']}</tns:OvertimePaymentA>\n`
    }
    if (!isNullorEmpty(data['Bonuses and Commissions Amount'])) {
        res = res + `<tns:GrossBonusesAndCommissionsA>${data['Bonuses and Commissions Amount']}</tns:GrossBonusesAndCommissionsA>\n`
    }
    if (!isNullorEmpty(data["Directors' Fees Amount"])) {
        res = res + `<tns:GrossDirectorsFeesA>${data["Directors' Fees Amount"]}</tns:GrossDirectorsFeesA>\n`
    }
    if (!isNullorEmpty(data['CDEP Amount'])) {
        res = res + `<tns:IndividualNonBusinessCommunityDevelopmentEmploymentProjectA>${data['CDEP Amount']}</tns:IndividualNonBusinessCommunityDevelopmentEmploymentProjectA>\n`
    }

    if (!isNullorEmpty(data['Paid Leave Type Code']) || !isNullorEmpty(data['Paid Leave Payment Amount'])) {
        res = res + `<tns:PaidLeaveCollection>\n<tns:PaidLeave>\n`;
        if (!isNullorEmpty(data['Paid Leave Type Code'])) {
            res = res + `<tns:TypeC>${data['Paid Leave Type Code']}</tns:TypeC>\n`
        }
        if (!isNullorEmpty(data['Paid Leave Payment Amount'])) {
            res = res + `<tns:PaymentA>${data['Paid Leave Payment Amount']}</tns:PaymentA>\n`
        }
        res = res + `</tns:PaidLeave>\n</tns:PaidLeaveCollection>\n`
    }

    if (!isNullorEmpty(data['Other Allowance Type Description'])
        || !isNullorEmpty(data['Payee Allowance Amount'])
        || !isNullorEmpty(data['Allowance Type Code'])
    ) {
        res = res + `<tns:AllowanceCollection>\n<tns:Allowance>\n`;
        if (!isNullorEmpty(data['Allowance Type Code'])) {
            res = res + `<tns:TypeC>${data['Allowance Type Code']}</tns:TypeC>\n`
        }
        if (!isNullorEmpty(data['Other Allowance Type Description'])) {
            res = res + `<tns:OtherAllowanceTypeDe>${data['Other Allowance Type Description']}</tns:OtherAllowanceTypeDe>\n`
        }
        if (!isNullorEmpty(data['Payee Allowance Amount'])) {
            res = res + `<tns:EmploymentAllowancesA>${data['Payee Allowance Amount']}</tns:EmploymentAllowancesA>\n`
        }
        res = res + `</tns:Allowance>\n</tns:AllowanceCollection>\n`
    }

    if (!isNullorEmpty(data['Salary Sacrifice Type Code']) || !isNullorEmpty(data['Salary Sacrifice Amount'])) {
        res = res + `<tns:SalarySacrificeCollection>\n<tns:SalarySacrifice>\n`;
        if (!isNullorEmpty(data['Salary Sacrifice Type Code'])) {
            res = res + `<tns:TypeC>${data['Salary Sacrifice Type Code']}</tns:TypeC>\n`
        }
        if (!isNullorEmpty(data['Salary Sacrifice Amount'])) {
            res = res + `<tns:PaymentsA>${data['Salary Sacrifice Amount']}</tns:PaymentsA>\n`
        }
        res = res + `</tns:SalarySacrifice>\n</tns:SalarySacrificeCollection>\n`
    }

    if (!isNullorEmpty(data['Lump Sum Financial Year'])
        || !isNullorEmpty(data['Lump Sum Payment Amount'])
        || !isNullorEmpty(data['Lump Sum Type Code'])
    ) {
        res = res + `<tns:LumpSumCollection>\n<tns:LumpSum>\n`;
        if (!isNullorEmpty(data['Lump Sum Type Code'])) {
            res = res + `<tns:TypeC>${data['Lump Sum Type Code']}</tns:TypeC>\n`
        }
        if (!isNullorEmpty(data['Lump Sum Financial Year'])) {
            res = res + `<tns:FinancialY>${data['Lump Sum Financial Year']}</tns:FinancialY>\n`
        }
        if (!isNullorEmpty(data['Lump Sum Payment Amount'])) {
            res = res + `<tns:PaymentsA>${data['Lump Sum Payment Amount']}</tns:PaymentsA>\n`
        }
        res = res + `</tns:LumpSum>\n</tns:LumpSumCollection>\n`
    }

    if (!isNullorEmpty(data['ETP Code']) ||
        !isNullorEmpty(data['Payee ETP Payment Date']) ||
        !isNullorEmpty(data['Payee Total ETP PAYG Amount']) ||
        !isNullorEmpty(data['Payee Termination Payment Taxable Component']) ||
        !isNullorEmpty(data['Payee Termination Payment Tax Free Component'])) {
        res = res + `<tns:EmploymentTerminationPaymentCollection>\n<tns:EmploymentTerminationPayment>\n`;
        if (!isNullorEmpty(data['ETP Code'])) {
            res = res + `<tns:IncomeTaxPayAsYouGoWithholdingTypeC>${data['ETP Code']}</tns:IncomeTaxPayAsYouGoWithholdingTypeC>\n`
        }
        if (!isNullorEmpty(data['Payee ETP Payment Date'])) {
            res = res + `<tns:IncomeD>${convertDateShortDateToISOInt(data['Payee ETP Payment Date'])}</tns:IncomeD>\n`
        }
        if (!isNullorEmpty(data['Payee Termination Payment Tax Free Component'])) {
            res = res + `<tns:IncomeTaxFreeA>${data['Payee Termination Payment Tax Free Component']}</tns:IncomeTaxFreeA>\n`
        }
        if (!isNullorEmpty(data['Payee Termination Payment Taxable Component'])) {
            res = res + `<tns:IncomeTaxableA>${data['Payee Termination Payment Taxable Component']}</tns:IncomeTaxableA>\n`
        }
        if (!isNullorEmpty(data['Payee Total ETP PAYG Amount'])) {
            res = res + `<tns:IncomePayAsYouGoWithholdingA>${data['Payee Total ETP PAYG Amount']}</tns:IncomePayAsYouGoWithholdingA>\n`
        }
        res = res + `</tns:EmploymentTerminationPayment>\n</tns:EmploymentTerminationPaymentCollection>\n`
    }

    res = res + `</tns:Remuneration>\n</tns:RemunerationCollection>\n`

    if (!isNullorEmpty(data['Deduction Type']) || !isNullorEmpty(data['Payee Deduction Amount'])) {
        res = res + `<tns:DeductionCollection>\n<tns:Deduction>\n`;
        if (!isNullorEmpty(data['Deduction Type'])) {
            res = res + `<tns:RemunerationTypeC>${data['Deduction Type']}</tns:RemunerationTypeC>\n`
        }
        if (!isNullorEmpty(data['Payee Deduction Amount'])) {
            res = res + `<tns:RemunerationA>${data['Payee Deduction Amount']}</tns:RemunerationA>\n`
        }
        res = res + `</tns:Deduction>\n</tns:DeductionCollection>\n`
    }
    if (!isNullorEmpty(data['Super Entitlement Type Code']) || !isNullorEmpty(data['Super Entitlement Amount'])) {
        res = res + `<tns:SuperannuationContributionCollection>\n<tns:SuperannuationContribution>\n`;
        if (!isNullorEmpty(data['Super Entitlement Type Code'])) {
            res = res + `<tns:EntitlementTypeC>${data['Super Entitlement Type Code']}</tns:EntitlementTypeC>\n`
        }
        if (!isNullorEmpty(data['Super Entitlement Amount'])) {
            res = res + `<tns:EmployerContributionsYearToDateA>${data['Super Entitlement Amount']}</tns:EmployerContributionsYearToDateA>\n`
        }
        res = res + `</tns:SuperannuationContribution>\n</tns:SuperannuationContributionCollection>\n`
    }
    if (!isNullorEmpty(data['RFB Exemption Status Code']) || !isNullorEmpty(data['Payee RFB Amount'])) {
        res = res + `<tns:IncomeFringeBenefitsReportableCollection>\n<tns:IncomeFringeBenefitsReportable>\n`;
        if (!isNullorEmpty(data['RFB Exemption Status Code'])) {
            res = res + `<tns:FringeBenefitsReportableExemptionC>${data['RFB Exemption Status Code']}</tns:FringeBenefitsReportableExemptionC>\n`
        }
        if (!isNullorEmpty(data['Payee RFB Amount'])) {
            res = res + `<tns:A>${data['Payee RFB Amount']}</tns:A>\n`
        }
        res = res + ` </tns:IncomeFringeBenefitsReportable></tns:IncomeFringeBenefitsReportableCollection>\n`
    }

    res = res + `</tns:PayrollPeriod>\n</tns:Payee>\n</tns:PAYEVNTEMP>\n`

    return res;
}

const isEmptyLine = (data: AllPropertyObject) => {
    let empty = true;
    Object.entries(data).forEach(([, value]) => {
        if (value) {
            empty = false;
        }
    });
    return empty;
}