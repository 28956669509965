import { useEffect, useReducer, useState } from "react";
import Steppers from "./Steppers";
import { ProvideReport, useReportContext } from "../Context/ReportContext";
import PrepareStep from "./Prepare/PrepareStep";
import ReviewStep from "./Review/ReviewStep";
import LodgeStep from "./Lodge/LodgeStep";
import Receipt from "./Receipt/Receipt";
import { Link, useHistory, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { ReactComponent as ArrowLeftShort } from "bootstrap-icons/icons/arrow-left-short.svg";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import ProcessingModal from "../../components/Modal/ProcessingModal";
import { toast } from "react-toastify";
import { ProcessIdParams } from "types/global";
import { NewReportAction, NewReportState } from "types/redux";
import { DeclarationResponseDeclarationType, STPDetailResponse } from "types/api";
import { STPDetailService } from "services/STPDetailService";

const initialState: NewReportState = {
  step: 1,
};

const reducer = (state: NewReportState, action: NewReportAction) => {
  switch (action.type) {
    case "change_step":
      return { ...state, step: action.payload.step };
    case "lodging":
      return { ...state, isLodging: true };
    case "lodge_failure":
      return { ...state, isLodging: false };
    case "lodge_success":
      return {
        ...state,
        isLodging: false,
        isLodged: true,
      };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

const StpNewReportPage = () => {
  const { report, setReport } = useReportContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isProcessing, setIsProcessing] = useState(false);
  const { processId } = useParams<ProcessIdParams>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const [isProcessingModalOpen, setIsProcessingModalOpen] = useState(false);

  const proceedHandler = async () => {
    setIsOpen(false);
    await cancelProcess();
  };

  const cancelProcess = async () => {
    setIsProcessing(true);
    try {
      await STPDetailService.cancel(report?.processId || "");
      setIsProcessing(false);
      toast.success(() => `${report?.processId} cancelled`, {
        autoClose: 3000,
      });
      history.push("/stp");
    } catch (err: any) {
      setIsProcessing(false);
      if (err.response) {
        console.log("Error - ", err);
      }
    }
  };

  // const submitReport = async (declarationType, processId) => {
  //   function x() {
  //     return new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         let r = HttpClient.post(`/stp-details/lodge/xml/${processId}`, {
  //           declarationType: declarationType,
  //         })
  //         resolve(r);
  //       }, 10000);
  //     });
  //  }

  //   let res = await x()
  //   return res;
  const submitReport = (declarationType: DeclarationResponseDeclarationType, processId: string) => {
    return STPDetailService.lodgeXml(processId, {
      declarationType: declarationType,
    });
  };

  const handleLodge = (declarationType: DeclarationResponseDeclarationType, processId: string) => {
    let didCancel = false;

    const lodgeRequest = async (declarationType: DeclarationResponseDeclarationType, processId: string) => {
      try {
        dispatch({ type: "lodging" });
        const submissionRes = await submitReport(declarationType, processId);
        setReport(submissionRes.data);

        if (!didCancel) {
          dispatch({ type: "lodge_success" });
        }
      } catch (err) {
        dispatch({ type: "lodge_failure" });
        console.log(err);
      }
    };

    lodgeRequest(declarationType, processId);
  };

  const retrieveSTPDetails = async () => {
    setIsProcessing(true);
    try {
      const res = await STPDetailService.retrieveSTPDetails(processId, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setReport(res.data);
      setIsProcessing(false);
      dispatch({ type: "change_step", payload: { step: res.data.step } });
    } catch (err: any) {
      if (err?.response?.data?.message?.includes("still in progress")) {
        setIsProcessing(false);
        setIsProcessingModalOpen(true);
      } else {
        setIsProcessing(false);
        console.log("Error - ", err);
        setReport({} as STPDetailResponse);
      }
    }
  };

  useEffect(() => {
    if (processId) {
      retrieveSTPDetails();
    }
  }, [processId, setReport]);

  return (
    <div>
      {isOpen && (
        <ConfirmationModal
          header="Cancel STP report"
          message="Are you sure you want to cancel this STP report? You will not be able to resume this submission."
          proceedHandler={proceedHandler}
          cancelHandler={() => {
            setIsOpen(false);
          }}
        />
      )}
      {isProcessingModalOpen && (
        <ProcessingModal
          proceedHandler={() => {
            setIsProcessingModalOpen(false);
            history.push("/");
          }}
        />
      )}
      {isProcessing ? (
        <Spinner />
      ) : (
        <div className="stp-new">
          {state.isLodged ? (
            <Receipt />
          ) : (
            <div>
              <div className="back-container mb-6">
                <Link to="/stp">
                  <ArrowLeftShort />
                  <span>Back to STP Reports</span>
                </Link>
              </div>

              {processId ? <h1>STP report</h1> : <h1>Create new STP report</h1>}
              {processId && (
                <h3>
                  {processId} |{" "}
                  {report?.stpVersion === "STP2" ? "STP 2" : "STP 1"}
                </h3>
              )}

              <section className="steppers-container">
                <Steppers
                  currentStep={state.step}
                  changeStep={(step: number) =>
                    dispatch({ type: "change_step", payload: { step: step } })
                  }
                />
              </section>

              <section>
                {state.step === 1 && (
                  <PrepareStep
                    dispatch={dispatch}
                    cancelProcess={() => setIsOpen(true)}
                  />
                )}

                {state.step === 2 && (
                  <ReviewStep
                    dispatch={dispatch}
                    cancelProcess={() => setIsOpen(true)}
                  />
                )}

                {state.step === 3 && (
                  <LodgeStep
                    dispatch={dispatch}
                    cancelProcess={() => setIsOpen(true)}
                    processState={state}
                    onLodge={() =>
                      handleLodge(
                        report?.payevntSummary?.declarationType as DeclarationResponseDeclarationType,
                        report?.processId || ""
                      )
                    }
                  />
                )}
              </section>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default function StpNewReport() {
  return (
    <ProvideReport>
      <StpNewReportPage />
    </ProvideReport>
  );
}
