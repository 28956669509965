import { useReportContext } from "../../Context/ReportContext";
import Tippy from "@tippyjs/react";
import { ReactComponent as QuestionCircle } from "bootstrap-icons/icons/question-circle.svg";
import classNames from "classnames";
import { STPDetailResponseEventType } from "types/api";

interface PrepareReportProps {
  eventTypeHandler: (eventType: STPDetailResponseEventType) => void;
}

const PrepareReport = ({ eventTypeHandler }: PrepareReportProps) => {
  const { report } = useReportContext();

  const handleEventTypeChange = (eventType: STPDetailResponseEventType) => {
    eventTypeHandler(eventType);
  };

  return (
    <div>
      <div className="mb-8" style={{ maxWidth: "36rem" }}>
        <h3 id="report-type" className="d-flex align-items-center">
          <span className="mr-2">Event type</span>
          <Tippy
            placement="right-start"
            theme="translucent"
            maxWidth={350}
            content={
              <div>
                Select the type of STP report you are uploading. <br />
                The Pay event option is preselected.
              </div>
            }
          >
            <QuestionCircle />
          </Tippy>
        </h3>
        <div className="button-switch">
          <button
            type="button"
            className={classNames("button", {
              "is-selected": report.eventType === "SUBMIT",
            })}
            onClick={() => {
              handleEventTypeChange("SUBMIT");
            }}
          >
            Pay event
          </button>
          <button
            type="button"
            className={classNames("button", {
              "is-selected": report.eventType === "UPDATE",
            })}
            onClick={() => {
              handleEventTypeChange("UPDATE");
            }}
          >
            Update event
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrepareReport;
