const EmployeeDetailsForm = () => {
  return (
    <form>
      <h2 className="mb-8">Employee details</h2>

      <div className="form-group">
        <label>Payroll ID</label>
        <input type="text" />
      </div>

      <fieldset>
        <legend className="h3">Personal details</legend>
        <div className="form-group">
          <label htmlFor="given-name">Given name</label>
          <input id="given-name" type="text" />
        </div>
        <div className="form-group">
          <label htmlFor="other-given-name">Other given name</label>
          <input id="other-given-name" type="text" />
        </div>
        <div className="form-group">
          <label htmlFor="family-name">Family name</label>
          <input id="family-name" type="text" />
        </div>
      </fieldset>

      <fieldset>
        <legend className="h3">Contact details</legend>
        <div className="form-group">
          <label htmlFor="mobile-number">Mobile number</label>
          <input id="mobile-number" type="text" />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input id="email" type="email" />
        </div>
        <div className="form-group align-items-start">
          <label htmlFor="address">Address</label>
          <input id="address" type="text" />
        </div>
      </fieldset>
    </form>
  );
};

export default EmployeeDetailsForm;
