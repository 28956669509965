import * as Yup from 'yup';
import {
  AUSTRALIAN_STATES_AND_TERRITORIES,
  PAYMENT_ARRANGEMENT_TERMINATION_CODES,
  RESIDENCY_TAX_PURPOSES_PERSON_STATUS_CODES,
  PAYMENT_ARRANGEMENT_PAYMENT_BASIS_CODES,
  LUMP_SUM_PAYMENT_A_TYPES,
  COUNTRY_CODES,
  ETP_CODES,
  ALLOWANCE_TYPES,
  DEDUCTION_TYPES
} from './configs';
import isNumber from 'lodash/isNumber';

// Payer daata schema
export const PayerDataSchema = Yup.object().shape({
  payevnt: Yup.object().shape({
    rp: Yup.object().shape({
      organisationName: Yup.object().shape({
        detailsOrganisationalNameT: Yup.string()
          .matches(
            /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/,
            'Invalid character(s)'
          )
          .max(200, 'Maximum 200 characters')
          .required('Required'),
        personUnstructuredNameFullNameT: Yup.string()
          .matches(
            /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/,
            'Invalid character(s)'
          )
          .max(200, 'Maximum 200 characters')
          .required('Required')
      }),
      australianBusinessNumberId: Yup.string().matches(/^[0-9]{11}$/, {
        message: 'Invalid',
        excludeEmptyString: true
      }),
      withholdingPayerNumberId: Yup.string().matches(/^[0-9]{11}$/, {
        message: 'Invalid',
        excludeEmptyString: true
      }),
      organisationDetailsOrganisationBranchC: Yup.string()
        .matches(/^(([1-9]|[1-9][0-9]|[1-9][0-9][0-9])*)$/, 'Invalid')
        .required('Required'),
      addressDetailsPostal: Yup.object().shape({
        line1T: Yup.string()
          .min(1, 'Minimum 1 character')
          .max(38, 'Maximum 38 characters')
          .required('Required'),
        line2T: Yup.string().max(38, 'Maximum 38 characters'),
        localityNameT: Yup.string()
          .min(1, 'Minimum 1 character')
          .max(46, 'Maximum 46 characters')
          .required('Required'),
        stateOrTerritoryC: Yup.string().when('countryC', {
          is: 'au',
          then: Yup.string()
            .required('Required')
            .oneOf(AUSTRALIAN_STATES_AND_TERRITORIES, 'Invalid')
        }),
        postcodeT: Yup.string().when('countryC', {
          is: value => value === 'au' || value === '',
          then: Yup.string()
            .required('Required')
            .matches(/^([0-9]{4})$/, 'Invalid')
        }),
        countryC: Yup.string().oneOf(
          COUNTRY_CODES.map(code => code.value),
          'Invalid'
        )
      }),
      electronicContact: Yup.object().shape({
        telephoneMinimalN: Yup.string()
          .min(1, 'Minimum 1 character')
          .max(16, 'Maximum 16 characters')
          .required('Required'),
        electronicMailAddressT: Yup.string()
          .email()
          .max(200, 'Maximum 200 characters')
      }),
      payroll: Yup.object().shape({
        paymentRecordTransactionD: Yup.string().required('Required'),
        incomeTaxAndRemuneration: Yup.object().shape({
          totalGrossPaymentsWithholdingA: Yup.number()
            .min(
              -99999999999.99,
              'The value must be more than or equal to -99999999999.99'
            )
            .max(
              99999999999.99,
              'The value must be less than or equal to 99999999999.99'
            )
            .required('Required'),
          payAsYouGoWithholdingTaxWithheldA: Yup.number()
            .min(
              -99999999999.99,
              'The value must be more than or equal to -99999999999.99'
            )
            .max(
              99999999999.99,
              'The value must be less than or equal to 99999999999.99'
            )
            .required('Required')
        })
      })
    })
  })
});

const paymentNumberSchema = Yup.number()
  .min(0, 'The value must be greater than or equal to 0')
  .max(
    99999999999.99,
    'The value must be less than or equal to 99999999999.99'
  );

const wageItemSchema = Yup.object().shape(
  {
    grossA: Yup.number().when('taxWithheldA', {
      is: val => val > 0,
      then: paymentNumberSchema.required('Required')
    }),
    taxWithheldA: Yup.number().when(
      'grossA',
      {
        is: val => val > 0,
        then: paymentNumberSchema.required('Required')
      },
      ['grossA', 'taxWithheldA']
    )
  },
  ['grossA', 'taxWithheldA']
);

const employmentTerminationPaymentSchema = Yup.object().shape({
  typeC: Yup.string()
    .oneOf(ETP_CODES)
    .required('Required'),
  paymentRecordPaymentEffectiveD: Yup.string().required('Required'),
  superannuationTaxFreeComponentA: paymentNumberSchema.required('Required'),
  superannuationEmploymentTerminationTaxableComponentTotalA: paymentNumberSchema.required(
    'Required'
  ),
  taxWithheldA: paymentNumberSchema.required('Required')
});

const allowanceItem = Yup.object().shape({
  typeC: Yup.string()
    .oneOf(ALLOWANCE_TYPES)
    .required('Required'),
  otherAllowanceTypeDe: Yup.string()
    .max(40, 'Maximum 40 characters')
    .nullable(),
  individualNonBusinessEmploymentAllowancesA: paymentNumberSchema.required(
    'Required'
  )
});

const deductionItem = Yup.object().shape({
  typeC: Yup.string()
    .oneOf(DEDUCTION_TYPES)
    .required('Required'),
  a: paymentNumberSchema.required('Required')
});

// Payee daata schema
export const PayeeDataSchema = Yup.object().shape({
  payevntemp: Yup.object().shape({
    payee: Yup.object().shape({
      identifiers: Yup.object().shape(
        {
          taxFileNumberId: Yup.string()
            .when('australianBusinessNumberId', {
              is: '',
              then: Yup.string().required('Required'),
              otherwise: Yup.string()
            })
            .matches(/^([0-9]{8,9})$/, {
              message: 'Invalid',
              excludeEmptyString: true
            }),
          australianBusinessNumberId: Yup.string().when('taxFileNumberId', {
            is: '',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
          })
        },
        ['taxFileNumberId', 'australianBusinessNumberId']
      ),
      personNameDetails: Yup.object().shape({
        familyNameT: Yup.string()
          .matches(
            /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/,
            'Invalid character(s)'
          )
          .min(1, 'Mininum 1 character')
          .max(40, 'Maximum 40 characters')
          .required('Required'),
        givenNameT: Yup.string()
          .matches(
            /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/,
            'Invalid character(s)'
          )
          .max(40, 'Maximum 40 characters'),
        otherGivenNameT: Yup.string()
          .matches(
            /^([0-9a-zA-Z .,?(){}:;'|\-_=\\/@#$%*=&"])*$/,
            'Invalid character(s)'
          )
          .max(40, 'Maximum 40 characters')
      }),
      addressDetails: Yup.object().shape({
        line1T: Yup.string()
          .min(1, 'Minimum 1 character')
          .max(38, 'Maximum 38 characters')
          .required('Required'),
        line2T: Yup.string().max(38, 'Maximum 38 characters'),
        localityNameT: Yup.string()
          .required('Required')
          .min(1, 'Minimum 1 character')
          .max(46, 'Maximum 46 characters'),
        stateOrTerritoryC: Yup.string().oneOf(
          AUSTRALIAN_STATES_AND_TERRITORIES,
          'Invalid'
        ),
        postcodeT: Yup.string().matches(/^([0-9]{4})$/, 'Invalid'),
        countryC: Yup.string()
      }),
      electronicContact: Yup.object().shape({
        telephoneMinimalN: Yup.string()
          .matches(/^([0-9a-zA-Z\s])*$/, 'Invalid characters')
          .max(16, 'Maximum 16 characters'),
        electronicMailAddressT: Yup.string()
          .email('Invalid email')
          .max(200, 'Maximum 200 characters')
      }),
      employerConditions: Yup.object().shape({
        employmentStartD: Yup.string(),
        employmentEndD: Yup.string()
      }),
      remunerationIncomeTaxPayAsYouGoWithholding: Yup.object().shape({
        payrollPeriod: Yup.object().shape({
          startD: Yup.string().required('Required'),
          endD: Yup.string().required('Required'),
          payrollEventFinalI: Yup.bool().required('Required')
        }),
        individualNonBusiness: wageItemSchema.shape({
          communityDevelopmentEmploymentProjectA: paymentNumberSchema,
          exemptForeignEmploymentIncomeA: paymentNumberSchema
        }),
        voluntaryAgreement: wageItemSchema,
        LabourHireArrangementPanel: wageItemSchema,
        specifiedByRegulationPayment: wageItemSchema,
        jointPetroleumDevelopmentAreaPayment: Yup.object().shape(
          {
            a: Yup.number().when(['taxWithheldA', 'foreignWithholdingA'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            }),
            taxWithheldA: Yup.number().when(['a', 'foreignWithholdingA'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            }),
            foreignWithholdingA: Yup.number().when(['taxWithheldA', 'a'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            })
          },
          [
            ['taxWithheldA', 'foreignWithholdingA'],
            ['a', 'foreignWithholdingA'],
            ['taxWithheldA', 'a']
          ]
        ),
        workingHolidayMaker: wageItemSchema,
        paymentToForeignResident: Yup.object().shape(
          {
            grossA: Yup.number().when(['taxWithheldA', 'foreignWithholdingA'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            }),
            taxWithheldA: Yup.number().when(['grossA', 'foreignWithholdingA'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            }),
            foreignWithholdingA: Yup.number().when(['taxWithheldA', 'grossA'], {
              is: (a, b) => a > 0 || b > 0,
              then: paymentNumberSchema.required('Required')
            })
          },
          [
            ['taxWithheldA', 'foreignWithholdingA'],
            ['grossA', 'foreignWithholdingA'],
            ['taxWithheldA', 'grossA']
          ]
        ),
        employmentTerminationPaymentCollection: Yup.object().shape({
          employmentTerminationPayment: Yup.array()
            .of(employmentTerminationPaymentSchema)
            .max(25, 'Maximum 25 ETPs accepted')
        }),
        allowanceCollection: Yup.object().shape({
          allowance: Yup.array().of(allowanceItem)
        }),
        deductionCollection: Yup.object().shape({
          deduction: Yup.array().of(deductionItem)
        }),
        unusedAnnualOrLongServiceLeavePayment: Yup.object().shape({
          lumpSumPaymentA: Yup.object().shape(
            {
              lumpSumAC: Yup.string().when('lumpSumAA', {
                is: val => isNumber(val) && val > 0,
                then: Yup.string()
                  .oneOf(LUMP_SUM_PAYMENT_A_TYPES, 'Invalid')
                  .required('Required')
              }),
              lumpSumAA: Yup.number().when('lumpSumAC', {
                is: val => val != null && val !== '',
                then: paymentNumberSchema.required('Required')
              })
            },
            ['lumpSumAA', 'lumpSumAC']
          ),
          lumpSumBA: wageItemSchema,
          lumpSumDA: wageItemSchema,
          lumpSumEA: wageItemSchema
        }),
        incomeFringeBenefitsReportable: Yup.object().shape({
          exemptIncomeFringeBenefitsReportableA: paymentNumberSchema,
          taxableIncomeFringeBenefitsReportableA: paymentNumberSchema
        }),
        superannuationContribution: Yup.object().shape({
          employerContributionsSuperannuationGuaranteeA: paymentNumberSchema,
          employerReportableA: paymentNumberSchema,
          ordinaryTimeEarningsA: paymentNumberSchema
        })
      }),
      onboarding: Yup.object().shape({
        tfnd: Yup.object().shape({
          paymentArrangementTerminationC: Yup.string().oneOf(
            PAYMENT_ARRANGEMENT_TERMINATION_CODES,
            'Invalid'
          ),
          residencyTaxPurposesPersonStatusC: Yup.string().oneOf(
            RESIDENCY_TAX_PURPOSES_PERSON_STATUS_CODES,
            'Invalid'
          ),
          paymentArrangementPaymentBasisC: Yup.string().oneOf(
            PAYMENT_ARRANGEMENT_PAYMENT_BASIS_CODES.map(code => code.value),
            'Invalid'
          ),
          incomeTaxPayAsYouGoWithholdingStudyAndTrainingLoanRepaymentI: Yup.bool(),
          studentLoanStudentFinancialSupplementSchemeI: Yup.bool(),
          taxOffsetClaimTaxFreeThresholdI: Yup.bool()
        }),
        declaration: Yup.object().shape({
          signatureD: Yup.string(),
          statementAcceptedI: Yup.bool()
        })
      })
    })
  })
});

export const STPSchema = Yup.object().shape({
  productId: Yup.string().required("STP product ID is required"),
  bmsName: Yup.string().required("BMS product name is required"),
  bmsVendor: Yup.string().required("BMS product vendor is required"),
  bmsVersion: Yup.string().required("BMS product version is required"),
});
