import { createRef, Component } from "react";
import classNames from "classnames";
import { ReactComponent as Upload } from "bootstrap-icons/icons/upload.svg";
import Alert from "../Alert/Alert";
import Papa from "papaparse";
import {
  AllPropertyObject,
  checkCSVColumns,
  generateXML,
} from "../../utils/mapper";
import { ParticipantDetailsResponse } from "types/api";
import { ValidationRes } from "pages/StpNew/Prepare/PrepareStep";
import { ParseResult } from "papaparse";

const getErrorMessage = (event: any) => {
  switch (event.target.error.code) {
    case event.target.error.NOT_FOUND_ERR:
      return "File Not Found!";
    case event.target.error.NOT_READABLE_ERR:
      return "File is not readable";
    case event.target.error.ABORT_ERR:
      break;
    default:
      return "An error occurred reading this file.";
  }
};

interface FileInputProps {
  onRead: (
    fileName: string,
    fileSize: number,
    fileType: string,
    fileData: string | ArrayBuffer,
    validationData?: ValidationRes
  ) => void;
  participant?: ParticipantDetailsResponse;
}

interface FileInputState {
  supportsAdvancedUpload: boolean;
  isDragOver: boolean;
  isUploading: boolean;
  errorMsg: any;
  unSupportedFileType: boolean;
}

class FileInput extends Component<FileInputProps, FileInputState> {
  fileInput: any;

  constructor(props: FileInputProps) {
    super(props);
    this.state = {
      supportsAdvancedUpload: true,
      isDragOver: false,
      isUploading: false,
      errorMsg: undefined,
      unSupportedFileType: false,
    };
    this.fileInput = createRef();
  }

  handleCsvUpload = (results: ParseResult<AllPropertyObject>, file: File) => {
    let validitionRes = checkCSVColumns(results.data);
    let res =
      !validitionRes || validitionRes.valid ? generateXML(results.data) : "";
    this.setState({ isUploading: false });
    this.props.onRead(file.name, file.size, file.type, res, validitionRes);
  };

  handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let supportedUploadFileTypes = this.getSupportedUploadFilesTypes();
    if (
      supportedUploadFileTypes.includes(this.fileInput?.current?.files[0].type)
    ) {
      if (this.fileInput?.current.files[0].type === "text/csv") {
        event.target.files &&
          Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: this.handleCsvUpload,
          });
      } else {
        this.handleSubmit(this.fileInput.current.files[0]);
      }
    } else {
      this.setState({ unSupportedFileType: true });
    }
  };

  handleSubmit = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ isUploading: false });
      const data = event?.target?.result;
      this.props.onRead(
        file.name,
        file.size,
        file.type,
        data as string | ArrayBuffer,
        { valid: true } as ValidationRes
      );
    };
    reader.onerror = (event) => {
      this.setState({ isUploading: false });
      this.setState({ errorMsg: getErrorMessage(event) });
      reader.abort();
    };
    reader.readAsText(file, "UTF-8");
  };

  handleDragStart = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ unSupportedFileType: false });
    this.setState({ isDragOver: true });
  };

  handleDragEnd = (event: React.DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ isDragOver: false });
  };

  handleDrop = (event: React.DragEvent<HTMLFormElement>) => {
    console.log("handleDrop", event);
    event.preventDefault();
    let supportedUploadFileTypes = this.getSupportedUploadFilesTypes();
    if (supportedUploadFileTypes.includes(event.dataTransfer.files[0].type)) {
      this.setState({ isDragOver: false, isUploading: true });
      if (event.dataTransfer.files[0].type === "text/csv") {
        Papa.parse(event.dataTransfer.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: this.handleCsvUpload,
        });
      } else {
        let droppedFile = event.dataTransfer.files[0];
        this.handleSubmit(droppedFile);
      }
    } else {
      this.setState({ unSupportedFileType: true });
    }
  };

  componentDidMount() {
    const div = document.createElement("div");
    const supportsAdvancedUpload =
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window;
    this.setState({ supportsAdvancedUpload });
  }

  getSupportedUploadFilesExtForParticipant() {
    let supportedTypesString = this.props.participant?.uploadFileTypes;
    let supportedTypes = supportedTypesString
      ? supportedTypesString.split(",")
      : [];

    let extensions = ".xml,.txt";
    supportedTypes.forEach((extType) => {
      if (extType === "CSV") {
        extensions = extensions + ",.csv";
      }
    });
    return extensions;
  }

  getSupportedUploadFilesTypes() {
    let supportedTypesString = this.props.participant?.uploadFileTypes;
    let supportedTypes = supportedTypesString
      ? supportedTypesString.split(",")
      : [];

    let extensions = ["text/xml", "text/plain"];
    supportedTypes.forEach((extType) => {
      if (extType === "CSV") {
        extensions.push("text/csv");
      }
    });
    return extensions;
  }

  render() {
    return (
      <>
        <form
          className={classNames({
            "file-input": true,
            "has-advanced-upload": this.state.supportsAdvancedUpload,
            "is-dragover": this.state.isDragOver,
            "is-uploading": this.state.isUploading,
          })}
          onDragOver={this.handleDragStart}
          onDragEnter={this.handleDragStart}
          onDragLeave={this.handleDragEnd}
          onDragEnd={this.handleDragEnd}
          onDrop={this.handleDrop}
          onBlur={() => this.setState({ unSupportedFileType: false })}
        >
          <div className="file-input__icon">
            <Upload />
          </div>
          <input
            type="file"
            name="file"
            id="file"
            accept={this.getSupportedUploadFilesExtForParticipant()}
            className="file-input__input"
            ref={this.fileInput}
            onChange={this.handleSelect}
          />
          <label htmlFor="file" className="file-input__label">
            <span>Drag and drop</span>
            <br />
            <small>OR</small>
            <br />
            <span className="file-input__dragndrop">Click to browse files</span>
          </label>
          <div className="file-input__uploading">Uploading&hellip;</div>
          {this.state.errorMsg && (
            <div className="file-input__error">{this.state.errorMsg}</div>
          )}
        </form>
        {this.state.unSupportedFileType && (
          <Alert variation="alert--failure" title={`File upload error`}>
            {() => {
              return (
                <div>
                  <p>{"File type not supported"}</p>
                </div>
              );
            }}
          </Alert>
        )}
        {/* { <Alert variation="alert--failure" title={`Validation Error`}>
        {() =>  this.state.validationRes.errorCode === 2 ?
            (
              <div>
                <p>{"Missing data"}</p>
              </div>
            ) : (
              <div>
                <p>{"Invalid data"}</p>
              </div>
            )
        }
      </Alert>} */}
      </>
    );
  }
}

export default FileInput;
