const EmploymentForm = () => {
  return (
    <form>
      <h2 className="mb-8">Employment</h2>

      <fieldset>
        <div className="form-group">
          <label htmlFor="start-date">Start date</label>
          <input id="start-date" type="text" />
        </div>
        <div className="form-group">
          <label htmlFor="employment-basis">Employment basis</label>
          <input id="employment-basis" type="text" />
        </div>
        <div className="form-group">
          <label htmlFor="abn">ABN</label>
          <input id="abn" type="text" />
        </div>
      </fieldset>
    </form>
  );
};

export default EmploymentForm;
