import { useContext } from "react";
import { Switch, useLocation } from "react-router-dom";
import Routes from "./Routes";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import SpinnerModal from "./components/Spinner/SpinnerModal";
import { ParticipantContext } from "./App";

const ProtectedRoutes = () => {
  const location = useLocation();

  const { isAuthenticated, isLoading } = useContext(ParticipantContext);

  return (
    <>
      {isLoading ?
        <SpinnerModal /> :
        <Switch location={location}>
          {isAuthenticated && Routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      }
    </>
  );
};

export default ProtectedRoutes;
