import jwt_decode from "jwt-decode";
import { API_HOST } from "./configs";
import axios from "axios";
import history from "./history.js";

export const getInfoFromAccessToken = async (accessToken: string) => {
  window.sessionStorage.setItem("access_token", accessToken);
  return await getApplicationToken(accessToken);
};

export const getApplicationToken = async (accessToken: string) => {
  try {
    const response = await axios.post(
      `${API_HOST}/token/`,
      accessToken
    );

    const decoded = jwt_decode<any>(response.data);
    const { exp } = decoded;
    window.sessionStorage.setItem("token", response.data);
    window.sessionStorage.setItem("token_expiry", String(Number(exp) * 1000)); // Save timestamp in milliseconds
    return decoded;
  } catch (error) {
    console.log("Error -", error);
    history.push("/forbidden");
  }
};

export const isValidToken = () => {
  let token = window.sessionStorage.getItem("token");
  let expiry = window.sessionStorage.getItem("token_expiry");
  if (!!token && !!expiry && Date.now() < Number(expiry)) {
    return true;
  } else {
    resetSessionStorage();
    return false;
  }
};

export const resetSessionStorage = () => {
  window.sessionStorage.removeItem("access_token");
  window.sessionStorage.removeItem("token");
  window.sessionStorage.removeItem("token_expiry");
};
