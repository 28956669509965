import React, { useEffect, useState } from "react";
import { ReactComponent as Plus } from "bootstrap-icons/icons/plus.svg";
import { ReactComponent as Refresh } from "../../assets/images/icons/bs-arrow-clockwise.svg";
import { useDebounce } from "use-debounce";
import { DEFAULT_PAGE_SIZE } from "../../utils/configs";
import HttpClient from "../../utils/HttpClient";
import ProcessDataRow from "./ProcessDataRow";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import PaginationBar from "../../components/PaginationBar/PaginationBar";
import Spinner from "../../components/Spinner/Spinner";

const InitialData = {
  totalPages: 0,
  totalElements: 0,
  number: 0,
  content: [],
};

const InitialError = {
  status: "",
  message: "",
};

const StpSummary = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState(InitialData);
  const [error, setError] = useState(InitialError);
  const [query, setQuery] = useState("");
  // Debounce the change of search query by 500ms to constant search requests while typing
  const [processId] = useDebounce(query, 500);
  const [status, setStatus] = useState("");
  const [eventType, setEventType] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [paginationData, setPaginationData] = useState({
    totalItems: 0,
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [reload, setReload] = useState(false);

  const setPaginationParams = (pageNumber, pageSize) => {
    setPaginationData({
      ...paginationData,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setIsLoaded(false);
  };

  const retrieveStpReportList = async () => {
    setIsProcessing(true);
    setError(InitialError);
    try {
      const res = await HttpClient.post(`/search/stp-history`, {
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
      });

      setIsLoaded(true);
      setData(res?.data?.rows);
      setPaginationData(res?.data?.paginationData);
      setError(InitialError);
      setIsProcessing(false);
      setReload(false);
    } catch (err) {
      setIsLoaded(true);
      setIsProcessing(false);
      if (err.response) {
        setData(InitialData);
        setError({
          status: err?.response?.status?.toString(),
          message: err?.response?.data?.message || err?.response?.data,
        });
      }
    }
  };

  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        retrieveStpReportList();
      }, 300000);
    } else {
      setReload(true);
    }
  }, [reload]);

  useEffect(() => {
    if (!isLoaded) {
      retrieveStpReportList();
    }
  }, [
    processId,
    status,
    eventType,
    fromDate,
    toDate,
    paginationData,
    isLoaded,
  ]);

  const isSuccessfullyLoaded = () => isLoaded && error.status.length === 0;

  const filtersApplied = () => {
    return (
      [processId, status, eventType].find((filter) => filter !== "") ||
      [fromDate, toDate].find((filter) => typeof filter !== "undefined")
    );
  };

  const resetFilters = () => {
    setQuery("");
    setStatus("");
    setEventType("");
    setFromDate(undefined);
    setToDate(undefined);
  };

  return (
    <div className="stp-summary">
      {/* <Breadcrumbs /> */}
      <div className="d-flex align-items-center justify-content-between mb-8">
        <h1 className="mb-0">STP Reports</h1>
        {isSuccessfullyLoaded() && data?.length > 0 && !filtersApplied() && (
          <Link
            to="/stp/new"
            className="button button--outline button--icon-before"
          >
            <Plus className="button--icon-before__icon" /> New STP report
          </Link>
        )}
      </div>

      {isProcessing ? (
        <Spinner />
      ) : (
        <div>
          {/* Error */}
          {isLoaded && error.status.length > 0 && (
            <div className="error-section">
              <h2>{error.status}</h2>
              <p>{error.message}</p>
            </div>
          )}
          {/* No reports lodged or in progress */}
          {isSuccessfullyLoaded() && data?.length === 0 && !filtersApplied() && (
            <section className="error-section">
              <h2>No STP Reports</h2>
              <p>You currently have no STP reports lodged or in progress</p>
              <p>
                <strong>
                  To create a new STP report make sure you have verified your
                  STP details in your{" "}
                  <Link
                    to="/settings/stp-details/STP1"
                    className="button button--link button--inline"
                  >
                    <strong> Settings</strong>
                  </Link>
                  .
                </strong>
              </p>

              <Link
                to="/stp/new"
                className="button button--primary button--icon-before"
              >
                <Plus className="button--icon-before__icon" /> New STP report
              </Link>
            </section>
          )}
          <section className="table-wrapper">
            {/*Table-header*/}
            {!(
              isSuccessfullyLoaded() &&
              data?.length === 0 &&
              !filtersApplied()
            ) && (
              <div className="table-header">
                {/*<div className="filters">*/}
                {/*  <Funnel className="mr-2" width="1.25rem" height="1.25rem" />*/}
                {/*  <SubmitDateFilter*/}
                {/*    fromDate={fromDate}*/}
                {/*    toDate={toDate}*/}
                {/*    setFromDate={setFromDate}*/}
                {/*    setToDate={setToDate}*/}
                {/*  />*/}
                {/*  <button type="button" className="Filter-button mr-2">*/}
                {/*    <span>Pay/update date</span>*/}
                {/*    <ChevronDown />*/}
                {/*  </button>*/}
                {/*  <button type="button" className="Filter-button mr-2">*/}
                {/*    <span>Branch ID</span>*/}
                {/*    <ChevronDown />*/}
                {/*  </button>*/}
                {/*  <EventTypeFilter*/}
                {/*    eventType={eventType}*/}
                {/*    setEventType={setEventType}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*  <div className="search-input">*/}
                {/*    <input*/}
                {/*      className="search-input__input"*/}
                {/*      type="search"*/}
                {/*      placeholder="Search"*/}
                {/*      aria-label="Search"*/}
                {/*      value={query}*/}
                {/*      onChange={(event) => setQuery(event.target.value)}*/}
                {/*    />*/}
                {/*    <Search className="search-input__icon" />*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div>
                  <div className="table-header__group">
                    <button
                      className="button button--sm button--icon"
                      onClick={retrieveStpReportList}
                    >
                      <Refresh />
                      <span className="ml-2">Refresh</span>
                    </button>
                  </div>
                  <div className="search-input">
                    {/* <Button onClick={retrieveStpReportList}>Reload</Button> */}
                  </div>
                </div>
              </div>
            )}

            {isSuccessfullyLoaded() && (
              <>
                {/* No matching filtered results */}
                {data?.length === 0 && filtersApplied() && (
                  <div className="error-section">
                    <h2>No matching results found</h2>
                    <button
                      type="button"
                      className="button button--primary button--icon-before"
                      onClick={resetFilters}
                    >
                      Reset filters
                    </button>
                  </div>
                )}

                {/* Table */}
                {data?.length > 0 && (
                  <div>
                    <div>
                      {/* <div className="table-bar">
                        <div className="table-bar__group">
                          <button
                            className="button button--sm button--icon"
                            onClick={retrieveStpReportList}
                          >
                            <Refresh />
                            <span className="ml-2">Refresh</span>
                          </button>
                        </div>
                      </div> */}
                      <table className="table table-stp">
                        <thead>
                          <tr>
                            <th>Process ID</th>
                            <th>Submission ID</th>
                            <th>Event</th>
                            <th>Gross payment</th>
                            <th>PAYGW amount</th>
                            <th>Lodge date</th>
                            <th>Pay/Update date</th>
                            <th>Processed date</th>
                            <th className="col-lg">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((row) => {
                            return (
                              <ProcessDataRow key={row.processId} row={row} />
                            );
                          })}
                        </tbody>
                      </table>
                      <PaginationBar
                        data={paginationData}
                        setPaginationParams={setPaginationParams}
                        resultType={"reports"}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </section>
        </div>
      )}
    </div>
  );
};

export default StpSummary;
