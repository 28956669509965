import { NavLink, Switch, useRouteMatch } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import RouteWithSubRoutes from "../../RouteWithSubRoutes";

const AddEmployee = ({ routes }) => {
  const { url } = useRouteMatch();

  return (
    <div className="add-employee">
      <Breadcrumbs />
      <h1 className="mb-8">Add employee</h1>

      <div className="flex-container">
        <div className="main-container">
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </div>

        <div className="aside-container">
          <ul class="add-employee__links">
            <li>
              <NavLink
                to={`${url}/employee-details`}
                activeClassName="is-active"
              >
                Employee details
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/employment`} activeClassName="is-active">
                Employment
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/taxes`} activeClassName="is-active">
                Taxes
              </NavLink>
            </li>
            <li>
              <NavLink to={`${url}/pay`} activeClassName="is-active">
                Pay
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${url}/super-contributions`}
                activeClassName="is-active"
              >
                Super contributions
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
