interface SpinnerProps {
  header?: string;
  message?: string
}

const Spinner = ({ header, message }: SpinnerProps) => {
  return (
      <div>
          <h3 className="mt-5 text-align-center">{header ? header : "Loading..."}</h3>
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
        <div className="my-4 text-align-center">
            <div dangerouslySetInnerHTML={{ __html: message ? message : "Please wait whilst we load the page" }} />
        </div>
    </div>
  );
};
export default Spinner;
