import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { useParams } from "react-router-dom";
import BMSIdForm from "./BMSIdForm";
import "react-tabs/style/react-tabs.css";
import React, { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import Spinner from "../../components/Spinner/Spinner";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Link, useParams, useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeftShort } from "bootstrap-icons/icons/arrow-left-short.svg";

const BMSIdDetails = () => {
  const { stpVersion } = useParams();
  const [selectedStpVersion, setSelectedStpVersion] = useState(stpVersion);
  const [tabIndex, setTabIndex] = useState(stpVersion === "STP1" ? 0 : 1);
  const [stpDetails, setStpDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        setSelectedStpVersion("STP1");
        break;
      case 1:
        setSelectedStpVersion("STP2");
        break;
      default:
        setSelectedStpVersion("STP1");
    }
  }, [tabIndex]);

  useEffect(() => {
    const getStpDetails = async () => {
      setIsLoading(true);
      try {
        const response = await HttpClient.get(
          `/participant/stp-details/${selectedStpVersion}`
        );
        const { data } = response;
        setStpDetails(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedStpVersion) {
      getStpDetails();
    } else {
      setIsLoading(false);
    }
  }, [selectedStpVersion]);

  return (
    <>
      {/* <Breadcrumbs /> */}
      <div className="back-container mb-6">
        <Link to="/settings">
          <ArrowLeftShort />
          <span>Back to Settings</span>
        </Link>
      </div>
      <h1 className="mb-8">STP details</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab selectedClassName="is-active">STP1</Tab>
            <Tab selectedClassName="is-active">STP2</Tab>
          </TabList>
          <TabPanel>
            <BMSIdForm stpDetails={stpDetails} />
          </TabPanel>
          <TabPanel>
            <BMSIdForm stpDetails={stpDetails} />
          </TabPanel>
        </Tabs>
      )}
    </>
  );
};

export default BMSIdDetails;
