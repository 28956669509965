import { Link } from "react-router-dom";
import {convertToAuFllDateFormat, getPayEventStatusPillClasses} from "../../utils/format";
import { getStatusValue } from "../../utils/displayLabelValues";
import Tippy from "@tippyjs/react";

const ProcessDataRow = ({ row }) => {
  return (
    <tr key={row.processId}>
      <td>
        {row.status === "INVALID_REQUEST" || row.status === "VALID_REQUEST" ? (
            <Tippy
                arrow={true}
                placement="top"
                animation="shift-away"
                theme="light-border"
                content={<span>Note: This draft report will be automatically archived if it is not lodged within 24 hours.</span>}
            >
                <div>
                    <Link to={`/stp/new/${row.processId}`}>{row.processId}</Link>
                </div>
            </Tippy>
        ) : row.status === "PROCESSING" ? (
          <Tippy
            arrow={true}
            placement="top"
            animation="shift-away"
            theme="light-border"
            content={
              <span>
                This STP report is currently processing due to its large file
                size. Please click the Refresh button intermittently to check if
                it has completed.
              </span>
            }
          >
            <div>
              <Link to="#" className="isDisabled">
                {row.processId}
              </Link>
            </div>
          </Tippy>
        ) : (
          <Link to={`/stp/${row.processId}`}>{row.processId}</Link>
        )}
      </td>
      <td>{row.submissionId}</td>
      <td>
        {row.eventType === "SUBMIT" ? <span>Pay</span> : <span>Update</span>}
      </td>
      <td>{row.grossPayment}</td>
      <td>{row.paygwAmount}</td>
      <td>{convertToAuFllDateFormat(row.submitDate)}</td>
      <td>{row.payUpdateDate}</td>
      <td>{row.processedDate}</td>
      <td>
        {row.status === "PROCESSING" ? (
          <Tippy
            arrow={true}
            placement="top"
            animation="shift-away"
            theme="light-border"
            content={
              <span>
                This STP report is currently processing due to its large file
                size. Please click the Refresh button intermittently to check if
                it has completed.
              </span>
            }
          >
            <span className="pill pill--loader">
              <span className="loader-horizontal"></span>
            </span>
          </Tippy>)
          :((row.status === "INVALID_REQUEST" || row.status === "VALID_REQUEST") ?
              (<Tippy
                  arrow={true}
                  placement="top"
                  animation="shift-away"
                  theme="light-border"
                  content={<span>Note: This draft report will be automatically archived if it is not lodged within 24 hours.</span>}
                  >
                <span className={`pill ${getPayEventStatusPillClasses(row?.status)}`}>
                    {getStatusValue(row?.status)}
                </span>
              </Tippy>)
              :(<span className={`pill ${getPayEventStatusPillClasses(row?.status)}`}>
                    {getStatusValue(row?.status)}
                </span>))}
      </td>
    </tr>
  );
};

export default ProcessDataRow;
