export const getStatusValue = (status) => {
  switch (status) {
    case "NEW":
      return "Draft";
    case "INVALID_REQUEST":
      return "Draft";
    case "VALID_REQUEST":
      return "Draft";
    case "CANCELLED":
      return "Cancelled";
    case "ARCHIVED":
      return "Archived";
    case "PROCESSING":
      return "Processing";
    case "SENDING":
      return "Sending to ATO";
    case "RE_SENDING":
      return "Re-sending to ATO";
    case "SENT":
      return "Sent";
    case "RECEIVED_BY_ATO":
      return "Waiting for response";
    case "COMPLETE":
      return "Complete";
    case "COMPLETE_WITH_WARNINGS":
      return "Complete w/ warnings";
    case "COMPLETE_WITH_ERRORS":
      return "Complete w/ errors";
    case "FAILED":
      return "Failed";
    default:
      return "Unknown";
  }
};

export const getAuditStatusValue = (auditEntryStatus, reportStatus) => {
  switch (auditEntryStatus) {
    case "FILE_UPLOADED":
      return { label: "STP file uploaded", status: "success" };
    case "FILE_REPLACED":
      return { label: "STP file replaced", status: "success" };
    case "VALIDATION_PASSED":
      return { label: "File validation passed", status: "success" };
    case "VALIDATION_SKIPPED":
      return { label: "File validation skipped", status: "success" };
    case "VALIDATION_IN_PROGRESS":
      switch (reportStatus) {
        case "VALIDATION_IN_PROGRESS":
          return { label: "Validation in progress", status: "pending" };
        default:
          return { label: "Validation completed", status: "success" };
      }
    case "VALIDATION_FAILED":
      return { label: "File validation failed", status: "alert" };
    case "PROCESS_CANCELLED":
      return { label: "Process cancelled", status: "cancelled" };
    case "PROCESS_ARCHIVED":
      return { label: "Process archived", status: "archived" };
    case "SUBMITTED_TO_ATO":
      switch (reportStatus) {
        case "SENDING":
          return { label: "Sending to ATO", status: "pending" };
        default:
          return { label: "Report lodged to the ATO", status: "success" };
      }
    case "RECEIVED_BY_ATO":
      return { label: "Report received by the ATO", status: "success" };
    case "WAITING_FOR_ATO_RESPONSE":
      return { label: "Waiting for ATO response", status: "pending" };
    case "COMPLETE":
      return { label: "Success", status: "success" };
    case "FAILED":
      return { label: "Failed", status: "alert" };
    case "COMPLETE_WITH_ERRORS":
      return { label: "Complete with errors", status: "success" };
    case "COMPLETE_WITH_WARNINGS":
      return { label: "Complete with warnings", status: "success" };
    default:
      return { label: "Unknown", status: "alert" };
  }
};

export const getValidationBadgeClass = (severity) => {
  switch (severity) {
    case "Error":
      return "badge--error";
    case "Warning":
      return "badge--warning";
    case "Information":
      return "badge--info";
    default:
      return "badge--error";
  }
};
