import { ReactComponent as Check } from "bootstrap-icons/icons/check.svg";
import { ReactComponent as ThreeDots } from "bootstrap-icons/icons/three-dots.svg";
import { ReactComponent as Exclamation } from "bootstrap-icons/icons/exclamation.svg";
import { ReactComponent as Cancel } from "../../assets/images/icons/bs-cross.svg";
import { getAuditStatusValue } from "../../utils/displayLabelValues";
import { useState } from "react";
import InformationModal from "../../components/Modal/InformationModal";
import ValidationMessages from "./ValidationMessages";
import { convertToAuFllTimeFormat } from "../../utils/format";
import { useHistory } from "react-router-dom";
import { DeclarationResponse, DeclarationResponseDeclarationType, STPAuditEntry, STPAuditEntryAction, STPDetailResponse, STPDetailResponseStatus } from "types/api";
import { STPDetailService } from "services/STPDetailService";

interface TimelineDotProps {
  auditEntry: STPAuditEntry;
  report: STPDetailResponse;
  declarationHandler?: () => Promise<void>;
}

const TimelineDot = ({ auditEntry, report, declarationHandler }: TimelineDotProps) => {
  const displayValues = getAuditStatusValue(auditEntry.action, report.status);
  const history = useHistory();

  return (
    <li>
      <div className="timeline-line">
        {displayValues.status === "success" && (
          <div className="timeline-icon is-success">
            <Check />
          </div>
        )}
        {displayValues.status === "pending" && (
          <div className="timeline-icon is-pending">
            <ThreeDots />
          </div>
        )}
        {displayValues.status === "alert" && (
          <div className="timeline-icon is-alert">
            <Exclamation />
          </div>
        )}
        {(displayValues.status === "cancelled" ||
          displayValues.status === "archived") && (
            <div className="timeline-icon is-cancelled">
              <Cancel />
            </div>
          )}
      </div>
      <div className="timeline-details">
        <div className="h3">{displayValues.label}</div>
        {auditEntry.action === "FILE_UPLOADED" && (
          <>
            <p>
              <span>{auditEntry.fullName}</span> uploaded STP file (
              {auditEntry?.auditData?.fileName}).
            </p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "VALIDATION_PASSED" && (
          <>
            <p>STP file ({report.fileName}) is valid.</p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "VALIDATION_IN_PROGRESS" && (
          <>
            {report?.status === "VALIDATION_IN_PROGRESS" ? (
              <>
                <p>STP file ({report.fileName}) is getting validated.</p>
                <p>
                  <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
                </p>
              </>
            ) : (
              <>
                <>
                  <p>STP file ({report.fileName}) is validated.</p>
                  <p>
                    <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
                  </p>
                </>
              </>
            )}
          </>
        )}
        {auditEntry.action === "VALIDATION_SKIPPED" && (
          <>
            <p>STP file ({report.fileName}) is not validated.</p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "VALIDATION_FAILED" && (
          <>
            <p>STP file ({report.fileName}) is invalid.</p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "FILE_REPLACED" && (
          <>
            <p>
              <span>{auditEntry.fullName}</span> replaced STP file with (
              {auditEntry?.auditData?.fileName})
            </p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "PROCESS_CANCELLED" && (
          <>
            <p>
              <span>{auditEntry.fullName}</span> cancelled the process.
            </p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "PROCESS_ARCHIVED" && (
          <>
            <p>
              This report was automatically archived as it was not lodged within
              24 hours of creation. If you wish to lodge this report, you must
              create a new STP report and&nbsp;
              <button
                className="button button--link button--exact-width"
                onClick={() => {
                  history.push("/stp/new");
                }}
              >
                reupload
              </button>{" "}
              the file again.
            </p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "WAITING_FOR_ATO_RESPONSE" as STPAuditEntryAction && (
          <>
            {report?.status === "SENDING" ? (
              <>
                <p>
                  Your file is being transmitted to the ATO. This may take a
                  number of minutes depending on file size and ATO systems.
                </p>
                <p>
                  <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
                </p>
              </>
            ) : (
              <>
                <p>
                  <span>{auditEntry.fullName}</span> signed the&nbsp;
                  <button
                    className="button button--link button--exact-width"
                    onClick={declarationHandler}
                  >
                    declaration
                  </button>{" "}
                  and lodged the report.
                </p>
                <p>
                  <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
                </p>
              </>
            )}
          </>
        )}
        {auditEntry.action === "RECEIVED_BY_ATO" && (
          <>
            <p>The ATO has received the report.</p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "SUBMITTED_TO_ATO" && (
          <>
            <p>
              Response times from the ATO can vary according to the size of the
              report and the status of the ATO's services. It can take from a
              few minutes to *72 hours* to receive a response, depending on
              these factors.
            </p>
          </>
        )}
        {auditEntry.action === "COMPLETE" && (
          <>
            <p>
              The ATO has successfully processed the report.
              <br />
              You have fulfilled your employer obligations to the ATO.
            </p>
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "FAILED" && (
          <>
            <p>
              You have not met your reporting obligation. You need to resolve
              the issues with your file and&nbsp;
              <button
                className="button button--link button--exact-width"
                onClick={() => {
                  history.push("/stp/new");
                }}
              >
                relodge
              </button>{" "}
              to ensure it reaches the ATO.
            </p>
            <ValidationMessages report={report} />
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "COMPLETE_WITH_ERRORS" && (
          <>
            <p>
              You have met your reporting obligation but the ATO has rejected
              one or more records. You do not need to lodge your file again,
              however you should review the errors and aim to resolve them prior
              to the next file upload.
            </p>
            <ValidationMessages report={report} />
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
        {auditEntry.action === "COMPLETE_WITH_WARNINGS" && (
          <>
            <p>
              You have met your reporting obligation but the ATO has issued one
              or more warnings. You do not need to lodge your file again,
              however you should review the warnings prior to the next file
              upload.
            </p>
            <ValidationMessages report={report} />
            <p>
              <time>{convertToAuFllTimeFormat(auditEntry.timeStamp)}</time>
            </p>
          </>
        )}
      </div>
    </li>
  );
};

interface TimelineProps {
  report: STPDetailResponse;
}

const Timeline = ({ report }: TimelineProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [declaration, setDeclaration] = useState<DeclarationResponse>();

  const showDeclaration = async () => {
    try {
      const res = await STPDetailService.getDeclarationContentUsingGET(
        report?.payevntSummary?.declarationType as DeclarationResponseDeclarationType,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setDeclaration(res.data);
      setIsOpen(true);
    } catch (err: any) {
      console.log(`${err.response.status}: ${err.response.statusText}`);
    }
  };
  return (
    <section className="section">
      <h2>Timeline</h2>
      <ul className="timeline">
        {report?.status === "RECEIVED_BY_ATO" && (
          <TimelineDot
            auditEntry={{ action: "WAITING_FOR_ATO_RESPONSE" }}
            report={report}
          />
        )}
        {report?.auditEntries?.map((it, index) => {
          return (
            <TimelineDot
              key={index}
              auditEntry={it}
              report={report}
              declarationHandler={showDeclaration}
            />
          );
        })}
      </ul>
      {isOpen && (
        <InformationModal
          header="Declaration"
          message={declaration?.declarationStatement}
          closeHandler={() => {
            setIsOpen(false);
          }}
        />
      )}
    </section>
  );
};

export default Timeline;
