import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { PayevntValidation } from "types/PayevntValidation";

export const groupErrorsByEmployer = (errors?: PayevntValidation[]) => {
    errors?.forEach(error => {
        error.employeeFullName = getFullName(error);
        error.isCompanyLevel = error.employeeFullName === "";
    });
    let sortedArray = sortBy(errors, ['contextDetails.companyName', 'employeeFullName'], ['asc', 'asc'])
    return groupByMulti(sortedArray, ['contextDetails.companyName', 'employeeFullName'])
};

export const groupErrorsByError = (errors?: PayevntValidation[]) => {
    errors?.forEach(error => {
        error.employeeFullName = getFullName(error);
        error.isCompanyLevel = error.employeeFullName === "";
    });
    let sortedArray = sortBy(errors, ['message', 'employeeFullName'], ['asc', 'asc'])
    return groupByMulti(sortedArray, ['message', 'employeeFullName'])
};

const getFullName = (error?: PayevntValidation) => {
    let fullName = null;
    if (error?.contextDetails?.employeeGivenName) {
        fullName = error.contextDetails.employeeGivenName + " ";
    }
    if (error?.contextDetails?.employeeFamilyName) {
        fullName = fullName + error.contextDetails.employeeFamilyName;
    }
    return !fullName ? "" : fullName;
}

const groupByMulti = (obj: PayevntValidation[], values: string[]) => {
    if (!values.length)
        return obj;
    var byFirst = groupBy(obj, values[0]),
        rest = values.slice(1);
    for (var prop in byFirst) {
        byFirst[prop] = groupByMulti(byFirst[prop], rest) as any;
    }
    return byFirst;
};