import { ReactComponent as Printer } from "bootstrap-icons/icons/printer.svg";
// import { ReactComponent as Envelope } from "bootstrap-icons/icons/envelope.svg";
import { ReactComponent as ArrowRight } from "bootstrap-icons/icons/arrow-right.svg";
import { ReactComponent as Tick } from "./tick.svg";
import { useReportContext } from "../../Context/ReportContext";
import { Link } from "react-router-dom";
import { convertToAuFllTimeFormat } from "../../../utils/format";

const Receipt = () => {
  const { report } = useReportContext();

  return (
    <div className="section">
      <div className="receipt-section mb-8">
        <div className="tick mb-6">
          <Tick />
        </div>
        <h1>Your STP report is lodged</h1>
        {report?.batch ? (
          <h3>
            {report?.eventType === "SUBMIT" ? "Pay" : "Update"} event report is
            successfully sent to the ATO
          </h3>
        ) : (
          <h3>
            {report?.eventType === "SUBMIT" ? "Pay" : "Update"} event report for{" "}
            {report.payevntSummary.payDate} is successfully sent to the ATO
          </h3>
        )}
        <hr />
        <p>
          Response times from the ATO can vary according to the size of the
          report and the status of the ATO’s services. It can take from a few
          minutes to 72 hours to receive a response, depending on these factors.
        </p>
      </div>

      {report && (
        <div className="receipt-section FileSummary p-0 mx-auto mb-8">
          <dl>
            {report?.processId && (
              <div>
                <dt>Process ID</dt>
                <dd>{report?.processId}</dd>
              </div>
            )}
            {report?.submittedAt && (
              <div>
                <dt>Lodged</dt>
                <dd>{convertToAuFllTimeFormat(report?.submittedAt)}</dd>
              </div>
            )}
            {!report?.batch && (
              <div>
                <dt>{report.eventType === "SUBMIT" ? "Pay" : "Update"} date</dt>
                <dd>{report.payevntSummary.payDate}</dd>
              </div>
            )}
          </dl>
        </div>
      )}

      <div className="text-align-center">
        <div className="mx-auto mb-8 d-print-none">
          <button
            type="button"
            className="button button--sm button--outline button--icon-before mr-4"
            onClick={window.print}
          >
            <Printer className="Icon button--icon-before__icon" />
            Print
          </button>
          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="button button--outline button--icon-before"*/}
          {/*>*/}
          {/*  <Envelope className="Icon button--icon-before__icon" />*/}
          {/*  Email*/}
          {/*</button>*/}
        </div>
        <div className="mx-auto mb-8 d-print-none">
          <Link to="/stp" className="button button-tertiary button--icon-after">
            Return to STP Reports
            <ArrowRight className="Icon button--icon-after__icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
