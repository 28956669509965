import { createContext, useContext, useState, ReactNode } from "react";
import { STPDetailResponse } from "types/api";

interface ReportContextValues {
  report: STPDetailResponse;
  setReport: React.Dispatch<React.SetStateAction<STPDetailResponse>>
}

interface ProvideReportProps {
  children: ReactNode;
}

export const ReportContext = createContext({} as ReportContextValues);

export const ProvideReport = ({ children }: ProvideReportProps) => {
  const [report, setReport] = useState<STPDetailResponse>({
    eventType: "SUBMIT",
  } as STPDetailResponse);

  return (
    <ReportContext.Provider value={{ report, setReport }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => useContext(ReportContext);
