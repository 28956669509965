import { STPDetailService } from "services/STPDetailService";
import { DownloadFileProps } from "./types/ReportService.types";

const downloadFile = ({ response }: DownloadFileProps) => {
  if (response?.data?.size === 0) {
    return false;
  }
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const urlObject = window.URL || window.webkitURL || window;
  const a = document.createElement('a');

  a.download = response.headers['content-disposition']
    ?.split('filename=')[1]
    ?.replace(/['"]+/g, '');
  a.href = urlObject.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  return true;
};

export class ReportService {
  static async downloadErrorMessageCSVSummary(
    processId: string,
  ): Promise<Boolean> {
    if (!processId) {
      return false;
    }
    const response = await STPDetailService.downloadSTPCsvReportUsingGET(processId, { responseType: 'blob' });

    return downloadFile({ response });
  }
}