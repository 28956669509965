import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo_wrkr-pay.svg";
import HttpClient from "../../utils/HttpClient";
import { ParticipantContext } from "../../App";

const TermsConditions = () => {
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);
  const { getParticipant } = useContext(ParticipantContext);

  const acceptTermsConditions = async () => {
    const res = await HttpClient.post("/participant/accept-terms-and-conditions");
    const { data } = res;
    await getParticipant();
    if (res.status === 200 && data.termsAndConditionsAccepted) {
      history.push("/");
    }
  };

  return (
    <div className="account-setup p-8 page__background">
      <div className="account-setup__header">
        <div className="logo mb-6">
          <Logo />
        </div>
        <h1>Terms and Conditions</h1>
        <h3>
          Please read and accept the Terms and Conditions before proceeding to
          Wrkr Pay
        </h3>
      </div>

      <div className="account-setup__section text-align-center">
        {/* ------------------------------START TERMS AND CONDITIONS------------------------- */}
        <div className="terms_conditions scroll-box">
          <h1>Wrkr Pay STP 2.0 Terms and Conditions</h1>
          <p>
            <i>Last update: June 2022</i>
          </p>
          <p>
            Welcome to the Wrkr Pay STP 2.0 Service,a service provided by Wrkr
            Pay Pty Ltd [ABN 48 122 693 985] for reporting payroll information
            to the ATO. These terms govern your access to, and use of, this
            Service, please read them carefully. These terms should be read in
            conjunction with the Agreement you have entered into with Wrkr Pay
            for the provision of these services.
          </p>
          <h2>TERMS</h2>
          <p>The parties agree as follows: </p>
          <h2>1. Defined Terms and Interpretation</h2>
          <h3>1.1 Definitions</h3>
          <p>Defined Terms:</p>
          <p>
            <strong>ATO</strong> means the Australian Taxation Office. ATO
            Operational Framework means the framework established by the ATO,
            which sets out the security measures with which digital service
            providers, including SSPs, must comply.
          </p>
          <p>
            <strong> Business Day</strong> means any day other than a Saturday,
            Sunday or public holiday in New South Wales.
          </p>
          <p>
            <strong>Business Management Software or BMS</strong> means the
            software (payroll or accounting software) used to maintain the
            employer and employee payroll information.
          </p>
          <p>
            <strong>Confidential Information</strong> of a party means the
            confidential, proprietary and commercially-sensitive information
            (irrespective of the form or the manner in which the information is
            disclosed, or the time of such disclosure) of that party (
            <strong>disclosing party</strong>) which his disclosed to, or learnt
            by or accessed by the other party (<strong>receiving party</strong>
            ), including information which:
            <ol className="list-alpha">
              <li>
                is identified by the disclosing party as confidential or the
                receiving party ought to have been known to be confidential; and
              </li>
              <li>
                relates to the business affairs and practices, including
                financial information, business opportunities, business plans,
                business processes and methodologies of the disclosing party,
                but does not include information:
              </li>
              <li>
                which is in, or comes into, the public domain other than by the
                receiving party's breach of these Terms;
              </li>
              <li>
                which is independently known to, or developed by, the receiving
                party as evidenced by the receiving party's written records; or
              </li>
              <li>
                is or was made available to the receiving party by a person
                (other than the disclosing party) who is not, or was not, under
                an obligation of confidence to the disclosing party.
              </li>
            </ol>
          </p>
          <p className="mt-2">
            <strong>Corporations Act</strong> means the Corporations Act 2001
            (Cth).
          </p>
          <p>
            <strong>Data File</strong> means the mechanism by which STP Data is
            sent by or for an Employer to the STP 2.0 Service, which must comply
            with the ATO specifications.
          </p>
          <p>
            <strong>Declaration</strong> means a declaration in the form
            prescribed by the ATO, which must be made prior to submitting a
            report of a Pay Event, an Update Event or Full File Replacement to
            the ATO.
          </p>
          <p>
            <strong>Documentation</strong> means the documentation made
            available on-line, from time to time, which sets out user
            instructions for the STP 2.0 Service.
          </p>
          <p>
            <strong>Employee</strong> means an employee of the Employer.
          </p>
          <p>
            <strong>Employer</strong> has the meaning given to it by the ATO,
            for the purposes of STP.
          </p>
          <p>
            <strong>Force Majeure Event</strong> means any act, event or cause
            including earthquakes, cyclones, floods, fires, lightening, storms
            or other acts of God, third party network, server and/or power
            outages, strikes or industrial disputes, riots, terrorist acts,
            civil disturbances, breakages of machinery, or industrial
            conditions, or arising out of any other unexpected and exceptional
            cause, delays in transportation and dispositions or orders of
            governmental authority, which:
            <ol className="list-alpha">
              <li>
                directly or indirectly results in a party being prevented from
                or delayed in performing any of its obligations under these
                Terms; and
              </li>
              <li>is beyond the reasonable control of that party</li>
            </ol>
          </p>
          <p>
            <strong>Full File Replacement</strong> means a Pay Event which
            replaces an entire file previously sent to the ATO.
          </p>
          <p>
            <strong>GST</strong> means the goods and services tax payable under
            the GST Law.
          </p>
          <p>
            <strong>GST Law</strong> has the same meaning as in the A New Tax
            System (Goods and Services Tax) Act 1999 (Cth).
          </p>
          <p>
            <strong>Law</strong> means any principle in common law or equity and
            any requirement under any legislation, regulatory requirement, rule,
            instrument, mandatory codes of conduct or code of practice in force
            or as applicable from time to time.
          </p>
          <p>
            <strong>Message</strong> means a set of data received by or sent by
            us through the STP 2.0 Service, in accordance with the SBR messaging
            standard.
          </p>
          <p>
            <strong>Nominated Account</strong> means the account from which we
            are authorised to arrange for the debit of a payment.
          </p>
          <p>
            <strong>Pay Event</strong> has the meaning given to it in the STP
            Standards.
          </p>
          <p>
            <strong>Personnel</strong> means officers, employees, contractors
            and agents.
          </p>
          <p>
            <strong>Personal Information</strong> has the same meaning as in the
            Privacy Act 1988 (Cth).
          </p>
          <p>
            <strong>Portal</strong> means the user interface available from the
            website and related information technology infrastructure.
          </p>
          <p>
            <strong>Privacy Laws</strong> means the Privacy Act 1988 (Cth), as
            amended or replaced from time to time, or such similar laws relating
            to the collection of Personal Information as may be applicable in
            the jurisdiction where the Personal Information is collected.
          </p>
          <p>
            <strong>Response</strong> means a Message sent by the ATO.
          </p>
          <p>
            <strong>Standard Business Reporting or SBR</strong> means the
            standardised approach to on-line or digital record keeping,
            established by the ATO
          </p>
          <p>
            <strong>SBR Network</strong> means the method of exchanging data
            with the ATO and other government agencies, in accordance with SBR.
          </p>
          <p>
            <strong>Sending Service Provider or SSP</strong> means an entity
            directly connected to the ATO, that provides the technical service
            for one or multiple employers, registered agents or payroll software
            providers to deliver payroll reports to the ATO in the correct
            technical format or through a permitted technical channel and
            receive Responses.
          </p>
          <p>
            <strong>Single Touch Payroll or STP</strong> means the requirement
            for an Employer to report certain payroll information in respect to
            their Employees to the ATO using SBR Network and in accordance with
            STP Standards.
          </p>
          <p>
            <strong>STP Data</strong> means the payroll data included by or for
            the Employer in a Data File, which must comply with the ATO
            specifications.
          </p>
          <p>
            <strong>STP 2.0 Service</strong> means the service provided by
            ClickSuper for reporting payroll information to the ATO.
          </p>
          <p>
            <strong>STP Standards</strong> means the legislation, regulation and
            legislative instruments and other standards issued by the ATO and
            other relevant authorities that are applicable to Single Touch
            Payroll, and include the ATO Operational Framework and the
            requirements of the Single Touch Payroll Phase 2: Business
            Implementation Guide issued by the ATO.
          </p>
          <p>
            <strong>Update Event</strong> means the mechanism that allows an
            Employer to report a change to STP Data reported to the ATO.
          </p>
          <p>
            <strong>User</strong> means a person authorised to access the STP
            2.0 Service.
          </p>
          <p>
            <strong>We, us or our</strong> means ClickSuper Pty Limited ABN 48
            122 693 985.
          </p>
          <p>
            <strong>You or Your</strong> means the Client.
          </p>
          <h3>1.2 Interpretation</h3>
          <p>
            In the Terms unless the context otherwise requires:
            <ol className="list-alpha">
              <li>
                a reference to these Terms or another instrument includes any
                variation or replacement of any of them;
              </li>
              <li>words in the singular includes the plural and vice versa;</li>
              <li>
                references to statutes include all statutes amending,
                consolidating or replacing such statutes;
              </li>
              <li>
                a reference to a person includes an individual, partnership,
                association, corporation or other body corporate or government
                agency;
              </li>
              <li>
                a reference to a corporation includes a reference to that
                corporation's administrators, representatives, successors and
                assigns;
              </li>
              <li>a reference to a month is a calendar month;</li>
              <li>
                if an event is to occur on a day which is not a Business Day
                then the day will be taken to be the next Business Day; and
              </li>
              <li>
                headings are included for convenience and do not affect the
                interpretation of this Agreement.
              </li>
            </ol>
          </p>
          <h2>2. Provision of the STP 2.0 Service</h2>
          <p>
            <strong>2.1</strong> From the date you accept these Terms, we agree
            to make the STP 2.0 Service available to You and the Employers using
            your software, in accordance with these terms and conditions.
          </p>
          <p>
            <strong>2.2</strong> You may advise Employers that the STP 2.0
            Service is available.
          </p>
          <p>
            <strong>2.3</strong> You must ensure all requisite details for the
            Employer are provided when on-boarding the Employer to the STP 2.0
            Service.
          </p>
          <p>
            <strong> 2.4</strong> You must understand and implement any
            requirements under the STP Standards, as applicable given your use
            of the STP 2.0 Service.
          </p>
          <h2>3. Availability of the STP 2.0 Service</h2>
          <p>
            <strong> 3.1</strong> We will make reasonable efforts to have the
            STP 2.0 Service available twenty four hours a day, seven days a
            week, except during downtimes for planned or unplanned maintenance
            or upgrades. Maintenance will, to the extent possible, be scheduled
            during periods of minimal use.
          </p>
          <p>
            <strong>3.2</strong> We do not warrant that use of the Portal will
            be free from interruption or delay, nor do we warrant that the
            Portal will be free from errors or faults. Our obligation in
            relation to unavailability, errors or defects affecting the Portal
            will be to use commercially reasonable efforts to repair or correct
            the Portal, as the case may be.
          </p>
          <h2>4. Client/User Access obligations</h2>
          <p>
            <strong>4.1</strong> You must ensure that:
            <ol className="list-alpha">
              <li>
                each User is authorised to gain access to the STP 2.0 Service;
              </li>
              <li>
                access to the Portal and the STP 2.0 Service is undertaken in a
                secure manner; and
              </li>
              <li>
                each User complies with the obligations and responsibilities
                included in these terms.
              </li>
            </ol>
          </p>
          <p>
            <strong>4.2</strong> A User's log-in and password must be kept safe
            and secure and should not be disclosed to another person. You agree
            and acknowledge that you are solely responsible for the
            confidentiality of Users log-in and password details and any use of
            the STP 2.0 Service through your registration, including
            unauthorised use.
          </p>
          <p>
            <strong>4.3</strong> You must notify us immediately if you are aware
            of any unauthorised use of the STP 2.0 Service through your
            registration.
          </p>
          <p>
            <strong>4.4</strong> We take no responsibility for any loss
            whatsoever where unauthorised parties have used your log-in save to
            the extent that we caused such loss by any negligent or wilful act
            or omission.
          </p>
          <p>
            <strong>4.5</strong> You must not and must use reasonable endeavours
            to ensure Users do not change, impair or damage the Portal.
          </p>
          <h2>5. ClickSuper obligations</h2>
          <p>
            <strong>5.1</strong> We have and will maintain the authorisations
            necessary to enable us to carry out the activities contemplated by
            these terms, including holding an Australian Financial Services
            Licence and the relevant registration with the ATO.
          </p>
          <p>
            <strong>5.2</strong> We will provide the STP 2.0 Service in
            accordance with:
            <ol className="list-alpha">
              <li>with these terms and conditions; and</li>
              <li>the applicable Law, including the STP Standards.</li>
            </ol>
          </p>
          <p>
            <strong>5.3</strong> We will ensure that sound and current controls
            are in place to ensure a high level of information and communication
            security in relation to the Employer's use of the STP 2.0 Service
            and ensure the integrity and proper operation of such controls and
            all associated procedures. These controls shall include systems to
            guard against the following risks: unauthorised data access, denial
            of service attacks, malevolent code (such as viruses), and data
            contamination.
          </p>
          <p>
            <strong>5.4</strong> We will:
            <ol className="list-alpha">
              <li>
                comply with our information and infrastructure security program
                which was established and is maintained in accordance with the
                Australian Standard on information security ISO 27001; and
              </li>
              <li>
                update and test the information and infrastructure security
                program, at least annually.
              </li>
            </ol>
          </p>
          <p>
            <strong>5.5</strong> We will:
            <ol className="list-alpha">
              <li>
                implement and utilise security measures, which comply with the
                requirements established by the ATO Operational Framework; and
              </li>
              <li>
                meet the audit requirements relevant to the provision of an STP
                2.0 Service, in accordance with the ATO guidelines.
              </li>
            </ol>
          </p>
          <p>
            <strong>5.6</strong> We will provide the STP 2.0 Service with all
            due care, skill and diligence expected of a professional service
            provider.
          </p>
          <p>
            <strong>5.7</strong> We will provide Documentation to assist with
            the use of the STP 2.0 Service.
          </p>
          <p>
            <strong>5.8</strong> We will not, and will ensure our Personnel do
            not, do anything or omit to do anything that may cause You to be in
            breach of any applicable Law.
          </p>
          <h2>6. Client obligations</h2>
          <p>
            <strong>6.1</strong> You have and will maintain the authorisations
            necessary to enable You to carry out the activities contemplated by
            these terms, including any relevant registration with the ATO.
          </p>
          <p>
            <strong>6.2</strong> You undertake to ensure that you have full
            authority and all necessary consents, as may be required from the
            Employer, to use the STP 2.0 Service on behalf of the Employer.
          </p>
          <p>
            <strong>6.3</strong> You must:
            <ol className="list-alpha">
              <li>
                not and must ensure that Your Personnel do not make any
                misrepresentations or engage in any misleading or deceptive
                conduct in relation to the STP 2.0 Service;
              </li>
              <li>
                not and must ensure Your Personnel do not give any warranties
                relating to the STP 2.0 Service;
              </li>
              <li>
                not and must ensure that Your Personnel do not do anything or
                omit to do anything that may cause us to be in breach of any
                applicable Law; and
              </li>
              <li>
                comply with any reasonable direction we provide in relation to
                the STP 2.0 Service.
              </li>
            </ol>
          </p>
          <p>
            <strong>6.4</strong> You acknowledge and agree you must not, and
            must ensure that Your Personnel do not (in whole or in part) copy,
            modify, decompile, decode or reverse engineer the systems by which
            we provide the STP 2.0 Service or any part, component, feature or
            other thing associated with the STP 2.0 Service, or the Portal.
          </p>
          <p>
            <strong>6.5</strong> Nothing in these terms or any use by the
            Employers of the STP 2.0 Service gives You any right or interest in
            any intellectual property rights in our systems (including the
            Portal).
          </p>
          <h2>7. STP Data submission</h2>
          <p>
            <strong>7.1</strong> You must:
            <ol className="list-alpha">
              <li>
                ensure that each Employer is informed that the Employer is
                solely responsible for the accuracy, completeness and legality
                of the STP Data transmitted using the STP 2.0 Service;
              </li>
              <li>
                ensure that each Employer is informed that it is the Employer's
                obligation to comply with the requirements of the STP Standards
                applicable to the Employer, including the provision of STP Data
                in a timeframe that will meets the Employer's obligations; and
              </li>
              <li>
                obtain from each Employer any permissions and authorisations
                necessary for Us to use, transmit, store and disclose the STP
                Data in connection with the provision of the STP 2.0 Service.
              </li>
            </ol>
          </p>
          <p>
            <strong>7.2</strong> You warrant and agree and represent that:
            <ol className="list-alpha">
              <li>
                You will only upload and transfer Data Files into the STP 2.0
                Service or disclose STP Data to Us, which You are fully entitled
                and authorised to upload, transfer and disclose; and
              </li>
              <li>
                the STP Data and Our collection, use, storage and/or disclosure
                thereof in the course of providing the STP 2.0 Service will not
                breach any applicable Law or right of any Employer or person.
              </li>
            </ol>
          </p>
          <p>
            <strong>7.3</strong> You must ensure that the Data Files provided to
            us comply with the STP Standards, otherwise we may not be able to
            provide the STP 2.0 Service.
          </p>
          <p>
            <strong>7.4</strong> You acknowledge and agree that:
            <ol className="list-alpha">
              <li>
                each Data File must be selected for upload and transmission to
                the ATO and that the appropriate Declaration must be completed,
              </li>
              <li>
                the Declaration will authorise us to lodge the Pay Event, Update
                Event or Full File Replacement and receive a Response; and
              </li>
              <li>
                if the user does not make the Declaration a Data File will not
                be uploaded to Us.
              </li>
            </ol>
          </p>
          <p>
            <strong>7.5</strong> When you upload a Data File we will prepare and
            transmit a Message to the ATO, in accordance with the STP Standards.
          </p>
          <p>
            <strong>7.6</strong> You acknowledge and agree that:
            <ol className="list-alpha">
              <li>
                the STP Data contained in the Data File will be validated by the
                ATO against their business rules;
              </li>
              <li>
                the Employer is responsible for ensuring that the STP Data sent
                to the ATO is, accurate, complete and complies with the
                requirements of the STP Standards; and
              </li>
              <li>
                the Employer must provide the Data File in a timeframe that
                ensures that the Employer will meet its obligations under the
                STP Standards.
              </li>
            </ol>
          </p>
          <p>
            <strong>7.7</strong> We accept no responsibility if a Data File is
            not submitted to us in time to make a submission of STP Data to the
            ATO by the deadline required under the STP Standards.
          </p>
          <p>
            <strong>7.8</strong> You acknowledge and agree that:
            <ol className="list-alpha">
              <li>
                a Data File cannot be changed or cancelled if conditions for
                transmission to the ATO have been met; and
              </li>
              <li>
                if a change or correction of STP Data is required, an Update
                Event or Full Replacement must be submitted in accordance with
                the STP Standards.
              </li>
            </ol>
          </p>
          <p>
            <strong>7.9</strong> We cannot guarantee that any data transmission
            over the internet is totally secure.
          </p>
          <p>
            <strong>7.10</strong> To the extent permitted by Law, You agree that
            we are not responsible for any loss, corruption or hacking of any
            STP Data.
          </p>
          <h2>8. Data maintenance</h2>
          <p>
            <strong>8.1</strong> The Data Files, Messages and Responses
            transmitted to and from the ATO using the STP 2.0 Service will pass
            through our system and the systems operated by or on behalf of the
            ATO.
          </p>
          <p>
            <strong>8.2</strong> You must ensure that each Employer is informed
            that the Employer is responsible for storing a copy of the STP Data
            uploaded to the STP 2.0 Service.
          </p>
          <p>
            <strong>8.3</strong> You acknowledge and agree that:
            <ol className="list-alpha">
              <li>
                we may retain the STP Data sent or received using the STP 2.0
                Service, as is necessary for us to provide the STP 2.0 Service
                or as required by Law, including the STP Standards; and
              </li>
              <li>
                whilst we will retain details of each transmission that has
                occurred using the STP 2.0 Service we may not retain the content
                of each Data File transmitted.
              </li>
            </ol>
          </p>
          <p>
            <strong>8.4</strong> You indemnify us in respect to any loss or
            damage we incur in respect to any claim that STP Data is lost,
            unavailable or corrupted or the transmission, storage, disclosure or
            access to any STP Data infringes the intellectual property rights or
            other rights of any person or breaches any Law
          </p>
          <h2>9. Privacy</h2>
          <p>
            <strong>9.1</strong> Your use of the STP 2.0 service may involve the
            transmission to us of certain Personal Information (as that term in
            commonly defined under privacy laws and regulations). Our policies
            with respect to the collection and use of such personal information
            are governed according to our Privacy Policy, (that you can access
            at{" "}
            <a href="https://wrkr.com.au/privacy-policy/" target="_blank">
              https://wrkr.com.au/privacy-policy
            </a>
            ), which is incorporated into, and forms part of, these Terms.
          </p>
          <p>
            <strong>9.2</strong> You must comply with all applicable Privacy
            Laws, including obtaining all necessary consents in relation to the
            use, disclosure, storage and processing of any Personal Information
            on the STP 2.0 Service.
          </p>
          <p>
            <strong>9.3</strong> You acknowledge and agree that Wrkr Pay may
            store or transfer Personal Information or any information it obtains
            from your use of the STP 2.0 Service to a location outside of
            Australia.
          </p>
          <h2>10. Confidentiality</h2>
          <p>
            <strong>10.1</strong> Both parties must keep all Confidential
            Information confidential and use such information for the sole
            purpose of performing the obligations under these Terms.
          </p>
          <p>
            <strong>10.2</strong> Neither party may use or disclose the
            Confidential Information except:
            <ol className="list-alpha">
              <li>
                for the reasonable purposes of fulfilling the party's
                obligations under these Terms or as otherwise permitted by these
                Terms;
              </li>
              <li>
                to that party's employees or advisers on a need-to-know basis
                and that party must ensure that such persons understand and
                comply with the obligations imposed by these Terms;
              </li>
              <li>
                as required by law, subject to that party notifying the other
                party immediately if that party becomes aware that such
                disclosure may be required; or
              </li>
              <li>with the other party's prior written consent.</li>
            </ol>
          </p>
          <h2>11. Insurance</h2>
          <p>
            <strong>11.1</strong> We will maintain appropriate insurance in
            relation to business, including without limitation, adequate
            insurance covering professional indemnity and fraud by our
            Personnel. We will, upon written request provide you with a copy of
            the certificates of currency for the insurances.
          </p>
          <h2>12. Limitation of Liability</h2>
          <p>
            <strong>12.1</strong> If you are a consumer (as that term is defined
            in the Competition and Consumer Act 2010 (Cth), or any similar
            legislation), then nothing in this Agreement is intended to exclude,
            restrict, or modify any statutory obligation of ours if it cannot be
            lawfully effected. Without limiting the generality of this clause,
            nothing in this Agreement (including in this clause 14) is intended
            to have the effect of excluding, restricting or modifying:
            <ol className="list-alpha">
              <li>
                the application of all or any of the provisions of Part 5-4 of
                Schedule 2 to the Competition and Consumer Act 2010 (the ACL);
                or
              </li>
              <li>the exercise of a right conferred by such a provision; or</li>
              <li>
                any liability we have in relation to a failure to comply with a
                guarantee that applies under Division 1 of Part 3-2 of the ACL
                to a supply of goods or services.
              </li>
            </ol>
          </p>

          <p>
            <strong>12.2</strong> To the extent permitted by law, except as
            expressly set out in this Agreement, we exclude all representations,
            conditions, warranties and guarantees arising from or in connection
            with this Agreement or its subject matter, whether based in statute,
            regulation or otherwise. Our liability for failing to comply with a
            representation, condition, warranty or guarantee that we cannot
            lawfully exclude is limited, at our option, to the resupply of the
            relevant goods or services, or payment of the cost of that resupply.
          </p>
          <p>
            <strong>12.3</strong> Except to the extent our own wilful or
            negligent acts or omissions cause loss or damage, we will not be
            responsible for and exclude all liability for loss or damage
            (including any indirect or consequential loss or damage), whether
            under contract, tort, statute or otherwise, that you may suffer or
            incur by reason of or in connection the STP 2.0 Service, or any act
            or omission by us. This clause 14.3 applies only to the extent
            permitted by Law.
          </p>
          <p>
            <strong>12.4</strong> If you suffers loss or damage as a result of
            our wilful or negligent acts or omissions, our total liability under
            any claims made from time to time arising out of those types of acts
            or omissions, whether under contract, tort, statute or otherwise,
            will be limited, in aggregate, to no more than five thousand
            dollars. This clause 14.4 applies only to the extent permitted by
            Law.
          </p>
          <p>
            <strong>12.5</strong> Except to the extent that our wilful or
            negligent acts or omissions cause loss or damage, you indemnify us
            against any liability, loss or damage suffered or incurred by us
            from your access to or use of the STP 2.0 Service, however that
            liability, loss or damage arises and regardless of who causes it,
            including any liability, loss or damage related to or arising out of
            any act or omission by you in breach of this Agreement. This clause
            14.5 applies only to the extent permitted by Law.
          </p>
          <h2>13. General provisions</h2>
          <p>
            <strong>13.1</strong> If a Force Majeure Event occurs, the affected
            party must notify the other party and the obligations of the party
            will be suspended to the extent that they are affected by the
            relevant Force Majeure Event until that Force Majeure Event has
            ceased.
          </p>
          <p>
            <strong>13.2</strong> Nothing in these Terms will be taken as giving
            rise to a relationship of employment, agency, partnership or joint
            venture. Except as otherwise provided in these Terms, the parties
            acknowledge and agree that neither party will have any authority to
            bind the other party or to enter into an agreement in the name of
            the other party.
          </p>
          <p>
            <strong>13.3</strong> We may assign or subcontract any of our rights
            or obligations in performing the Platform under these Terms at any
            time without your prior written consent.
          </p>
          <p>
            <strong>13.4</strong> This agreement contains the entire
            understanding between the parties concerning the subject matter of
            the agreement and supersedes all prior communications.
          </p>
          <p>
            <strong>13.5</strong> The failure of either party to enforce any
            provisions under these Terms will not waive the right of such party
            thereafter to enforce any such provisions.
          </p>
          <p>
            <strong>13.6</strong> If any term or provision of these Terms is
            held by a court to be illegal, invalid or unenforceable under the
            applicable law, that term or provision will be severed from these
            Terms and the remaining terms and conditions will be unaffected.
          </p>
          <p>
            <strong>13.7</strong> Any warranty, indemnity, or obligation of
            confidentiality in these Terms will survive termination. Any other
            term which by its nature is intended to survive termination of these
            Terms survives termination of these Terms.
          </p>
          <p>
            <strong>13.8</strong> This agreement is governed by, and construed
            in accordance with, the laws of New South Wales, Australia. The
            parties agree to submit to the non-exclusive jurisdiction of the
            courts of New South Wales.
          </p>
        </div>
        {/* ------------------------------END TERMS AND CONDITIONS------------------------- */}
        <div className="checkbox">
          <input
            id="terms"
            name="terms"
            type="checkbox"
            checked={accepted}
            onChange={(event) => setAccepted(event.target.checked)}
          />
          <label htmlFor="terms">
            I have read and accept the Terms and Conditions
          </label>
        </div>
      </div>

      <div>
        <button
          type="button"
          className="button button--primary button--full-width"
          disabled={!accepted}
          onClick={acceptTermsConditions}
        >
          <span>Continue to Wrkr Pay</span>
        </button>
      </div>
    </div>
  );
};

export default TermsConditions;
