import classNames from "classnames";

const Steppers = ({ currentStep, changeStep }) => {
  return (
    <ol className="steppers">
      <li
        className={classNames({
          steppers__step: true,
          "is-active": currentStep === 1,
          "is-complete": currentStep > 1,
        })}
      >
        <button className="button" type="button" onClick={() => changeStep(1)}>
          Prepare
        </button>
      </li>
      <li
        className={classNames({
          steppers__step: true,
          "is-active": currentStep === 2,
          "is-complete": currentStep > 2,
        })}
      >
        <button
          className="button"
          type="button"
          disabled={currentStep < 2}
          onClick={() => changeStep(2)}
        >
          Review
        </button>
      </li>
      <li
        className={classNames({
          steppers__step: true,
          "is-active": currentStep === 3,
          "is-complete": currentStep > 3,
        })}
      >
        <button
          className="button"
          type="button"
          disabled={currentStep < 3}
          onClick={() => changeStep(3)}
        >
          Lodge
        </button>
      </li>
    </ol>
  );
};

export default Steppers;
