import { ReactComponent as Logo } from "../../assets/images/logo_wrkr-pay.svg";
import {backToClickSuper} from "../../utils/siteNavigation";

const Forbidden = () => {
  return (
    <div className="page__background">
      <div className="mb-8">
        <Logo />
      </div>
      <h1 className="title"></h1>
      <h1>Scheduled Maintenance - 24th November 8pm-1am</h1>
      <p className="longform mb-2">
        Single Touch Payroll is currently unavailble due to scheduled maintenance. 
      </p>
      <p className="longform mb-8">
        Please return to ClickSuper and try again at a later time.
      </p>
      <p>
        Additional information can be found <a target="_blank" href="https://support.wrkr.com.au/hc/en-au/articles/24991259077401-Planned-STP-Outage-Schedule-for-Wrkr-PAY">here</a>
        , or please contact our support team for any other enquiries support@clicksuper.com.au.
        <br />
      </p>
      <button className="button button--primary" onClick={() => backToClickSuper()}>Return to ClickSuper</button>
    </div>
  );
};

export default Forbidden;
