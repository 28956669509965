import Accordion from "../../../components/Accordion/Accordion";

interface ErrorsGroupedByEmployerProps {
  errors: any;
  allExpanded: boolean;
}

const ErrorsGroupedByEmployer = ({ errors, allExpanded }: ErrorsGroupedByEmployerProps) => {
  const getErrorCount = (error: any) => {
    let errorCount = 0;
    Object.keys(error[0]).map((key1) => {
      errorCount = errorCount + error[0][key1].length;
    });
    return errorCount;
  };

  return (
    <div>
      {errors.map((error: any, index: number) => {
        return (
          <Accordion
            key={index}
            expanded={!!allExpanded}
            title={`<span class="accordion__title--primary">${error[0][Object.keys(error[0])[0]][0]?.contextDetails?.companyName
              }</span> 
                    <span class="accordion__title--secondary">(${error[0][Object.keys(error[0])[0]][0]?.contextDetails
                ?.companyIdentifierType
              }: 
                    ${error[0][Object.keys(error[0])[0]][0]?.contextDetails
                ?.companyIdentifier
              } 
                    | Submission ID: ${error[0][Object.keys(error[0])[0]][0]?.contextDetails
                ?.submissionId
              })</span>`}
            subTitle={getErrorCount(error)}
            content={
              <div>
                <div className="accordion-container__lvl-2">
                  {Object.keys(error[0]).map((key1) => {
                    return (
                      <Accordion
                        key={key1}
                        expanded={!!allExpanded}
                        title={
                          error[0][key1][0]?.isCompanyLevel
                            ? "Organisation Level Errors"
                            : `<span>${error[0][key1][0]?.contextDetails?.employeeGivenName}  
                                ${error[0][key1][0]?.contextDetails?.employeeFamilyName}</span>
                                <span class="accordion__title--secondary"> (Payroll ID: ${error[0][key1][0]?.contextDetails?.employeePayrollId})</span>`
                        }
                        subTitle={error[0][key1].size}
                        content={
                          <div className="accordion-container__lvl-3 px-4">
                            {error[0][key1].map((error1: any, index1: number) => {
                              return (
                                <Accordion
                                  key={index1}
                                  expanded={!!allExpanded}
                                  title={`<span class="badge badge--error">${error1.severity}</span><span> ${error1.message}</span>`}
                                  content={
                                    <div>
                                      {error1.details ? (
                                        <p className="mb-2">{error1.details}</p>
                                      ) : (
                                        <p className="mb-2">{error1.message}</p>
                                      )}
                                      <p className="p--sm mb-0">
                                        Message code: {error1.errorCode}
                                      </p>
                                      {error1?.fieldName && (
                                          <p className="p--sm mb-0 field-name">
                                            Field name: {error1.fieldName}
                                          </p>
                                      )}

                                      {error1?.value && (
                                          <p className="p--sm mb-0">
                                            Field value: {error1.value}
                                          </p>
                                      )}

                                      {error1?.path && (
                                        <p className="p--sm mb-0 field-name">
                                        Field location: {error1.path}
                                      </p>
                                      )}

                                    </div>
                                  }
                                />
                              );
                            })}
                          </div>
                        }
                      />
                    );
                  })}
                </div>
              </div>
            }
          />
        );
      })}
    </div>
  );
};

export default ErrorsGroupedByEmployer;
