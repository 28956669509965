import { STPDetailResponse } from "types/api";

interface ReportSummaryProps {
    report: STPDetailResponse;
}

const ReportSummary = ({ report }: ReportSummaryProps) => {
    return (
        <>
            {report?.payevntSummary && (
                <article className="file-summary">
                    <div>
                        <h1>
                            Submission ID:{" "}
                            {report.payevntSummary.submissionId}
                        </h1>
                    </div>

                    <div className="split">
                        <div>
                            <span className="number">{report.payevntSummary.numberOfEmployers}</span>
                            <span>Employers</span>
                        </div>
                        <div>
                            <span className="number">{report.payevntSummary.numberOfEmployees}</span>
                            <span>Employees</span>
                        </div>
                    </div>

                    {report?.eventType === "SUBMIT" && (
                        <>
                            <div>
                                <dt>Gross</dt>
                                <dd>{report.payevntSummary.totalGross}</dd>
                                <dt>PAYGW</dt>
                                <dd>{report.payevntSummary.totalPayGW}</dd>
                                {report.payevntSummary.totalChildSupportGarnisheeAmount && (
                                    <>
                                        <dt>Child support garnishee</dt>
                                        <dd>{report.payevntSummary.totalChildSupportGarnisheeAmount}</dd>
                                    </>
                                )}
                                {report.payevntSummary.totalChileSupportDeductionsAmount && (
                                    <>
                                        <dt>Child support deduction</dt>
                                        <dd>{" "}{report.payevntSummary.totalChileSupportDeductionsAmount}</dd>
                                    </>
                                )}
                            </div>
                            <div className="total">
                                <dt>Total</dt>
                                <dd>{report.payevntSummary.total}</dd>
                            </div>
                        </>
                    )}

                </article>
            )}
        </>
    );
};
export default ReportSummary;