
const CloseButton = (props) => {
    return (
        <button
            type="button"
            className="button button--outline"
            {...props}
        >
            <span>Close</span>
        </button>
    );
};

export default CloseButton;
