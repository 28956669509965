import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Routes from "../../Routes";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(Routes);

  return (
    <div className="breadcrumbs mb-6">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <span key={match.url}>
          <NavLink to={match.url}>{breadcrumb}</NavLink>
          {index < breadcrumbs.length - 1 && " / "}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
