import ReportSummaryDetails from "./ReportSummaryDetails";
import Timeline from "./Timeline";
import {useReportContext} from "../Context/ReportContext";

const StpDetailsHome = () => {
  const { report } = useReportContext();

  return (
    <div className="flex-container">
      <div className="main-container">
        <Timeline report={report}/>
      </div>

      <div className="aside-container">
        {report && <ReportSummaryDetails report={report} />}
      </div>
    </div>
  );
};

export default StpDetailsHome;
