import { getFileSize } from "../../../utils/format";
import { ReactComponent as FileEarmark } from "bootstrap-icons/icons/file-earmark.svg";

interface UploadFileProps {
  fileName?: string;
  fileSize?: any;
  handleReset: () => Promise<void>;
  cancelHandler: () => Promise<void>;
  isReplace: boolean;
}

const UploadFile = ({
  fileName,
  fileSize,
  handleReset,
  cancelHandler,
  isReplace,
}: UploadFileProps) => {
  return (
    <div className="uploaded-file">
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <FileEarmark />
        </div>
        <strong className="file-name mr-2">{fileName}</strong>{" "}
        {fileSize && (
          <div>
            <small className="file-size mr-4">({getFileSize(fileSize)})</small>
          </div>
        )}
        <div className="icon-tick">
          {/* <CheckCircleFill style={{ color: "#7eda92" }} /> */}
        </div>
      </div>
      <div style={{ width: "100px" }}>
        {isReplace ? (
          <button
            type="button"
            className="button button--link"
            aria-label="Cancel"
            onClick={cancelHandler}
          >
            Cancel
          </button>
        ) : (
          <button
            type="button"
            className="button button--link"
            aria-label="Replace file"
            onClick={handleReset}
          >
            Replace
          </button>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
