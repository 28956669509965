import StpSummary from "./pages/StpSummary/StpSummary";
import StpDetails from "./pages/StpDetails/StpDetails";
import StpNewReport from "./pages/StpNew/StpNewReport";
import Employees from "./pages/Employees/Employees";
import Settings from "./pages/Settings/Settings";
import BMSIdDetails from "./pages/Settings/BMSIdDetails";
import AddEmployee from "./pages/AddEmployee/AddEmployee";
import EmployeeDetailsForm from "./pages/AddEmployee/EmployeeDetailsForm";
import EmploymentForm from "./pages/AddEmployee/EmploymentForm";

const Routes = [
  // {
  //   path: "/",
  //   breadcrumb: null,
  //   component: Dashboard,
  //   exact: true,
  // },
  {
    path: "/",
    breadcrumb: null,
    component: StpSummary,
    exact: true,
  },
  {
    path: "/stp",
    breadcrumb: "STP Reports",
    component: StpSummary,
    exact: true,
  },
  {
    path: "/stp/new",
    breadcrumb: "New STP Report",
    component: StpNewReport,
    exact: true,
  },
  {
    path: "/stp/new/:processId",
    breadcrumb: ({ match }) => <span>{match.params.processId}</span>,
    component: StpNewReport,
    exact: true,
  },
  {
    path: "/stp/:processId",
    breadcrumb: ({ match }) => <span>{match.params.processId}</span>,
    component: StpDetails,
    exact: true,
  },
  {
    path: "/employees",
    breadcrumb: "Employees",
    component: Employees,
    exact: true,
  },
  {
    path: "/employees/new",
    breadcrumb: "Add employee",
    component: AddEmployee,
    routes: [
      {
        path: "/employees/new",
        breadcrumb: "Employee details",
        component: EmployeeDetailsForm,
        exact: true,
      },
      {
        path: "/employees/new/employee-details",
        breadcrumb: "Employee details",
        component: EmployeeDetailsForm,
        exact: true,
      },
      {
        path: "/employees/new/employment",
        breadcrumb: "Employment",
        component: EmploymentForm,
        exact: true,
      },
    ],
  },
  {
    path: "/settings",
    breadcrumb: "Settings",
    component: Settings,
    exact: true,
  },
  {
    path: "/settings/stp-details",
    breadcrumb: "STP Details",
    component: BMSIdDetails,
    exact: true,
  },
  {
    path: "/settings/stp-details/:stpVersion",
    breadcrumb: ({ match }) => `${match.params.stpVersion}`,
    component: BMSIdDetails,
    exact: true,
  },
];

export default Routes;
