import Modal from "react-modal";
import CloseButton from "../Buttons/CloseButton";

Modal.setAppElement("#root");

const InformationModal = ({ header, message, closeHandler }) => {
  return (
    <Modal
      className="modal"
      overlayClassName="modal-overlay"
      isOpen={true}
      contentLabel="Loading"
    >
      <h3 className="mt-5">{header ? header : "Information"}</h3>
      <p className="my-4">
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </p>
      <CloseButton onClick={closeHandler} />
    </Modal>
  );
};

export default InformationModal;
