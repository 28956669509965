import { cancel, lodgeXml, retrievePreSignedUrl, retrieveSTPDetails, submit, updateStep, update, validateXml, getDeclarationContent, downloadSTPCsvReport } from "types/api/services/stp/stp";

export class STPDetailService {
    static retrieveSTPDetails = retrieveSTPDetails;

    static cancel = cancel;

    static lodgeXml = lodgeXml;

    static retrievePreSignedUrl = retrievePreSignedUrl;

    static submit = submit;

    static update = update;

    static validateXml = validateXml;

    static updateStep = updateStep;

    static getDeclarationContentUsingGET = getDeclarationContent;

    static downloadSTPCsvReportUsingGET = downloadSTPCsvReport;

}