import { ReactComponent as ArrowLeft } from "bootstrap-icons/icons/arrow-left.svg";

const BackButton = (props) => {
  return (
    <button
      type="button"
      className="button button--outline button--back"
      {...props}
    >
      <ArrowLeft />
      <span>Back</span>
    </button>
  );
};

export default BackButton;
